import React, { useEffect } from "react";
import AllRoute from "../router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addUserCart, updateConfigs, logout } from "../../store/actions/action";
import { connect } from "react-redux";
import LoginModal from "../../components/Modal/LoginModal";
import { useCart } from "../../Context/CartContext";
import axios from "axios";
import { isEmpty } from "lodash";
import { store } from "../../store";
import { checkLoggedIn } from "../../utils";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const logoutUrls = [
      "get-cart",
      "userdetails",
      "/auth/orders",
      "auth/get-address",
      "auth/create-order",
    ];
    const responseURL = error?.request?.responseURL;
    if (error.response) {
      if (error.response.status === 401 && checkLoggedIn()) {
        if (logoutUrls.some((url) => responseURL?.includes(url))) {
          store?.dispatch(logout());
        }
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);

const App = (props) => {
  const { updateConfigs, configs, carts } = props;
  const { isAuth, token } = configs;
  const { cart, getUserCartData } = useCart();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        updateConfigs({
          ip_data: {
            country_calling_code: data?.country_calling_code,
            country_code: data?.country_code,
          },
        });
      })
      .catch((error) => console.error("Error fetching IP info:", error));
    handelGetAdminConditions();
  }, []);

  const handelGetAdminConditions = async () => {
    await axios
      .get("get-logo")
      .then((res) => {
        updateConfigs({ admin_conditions: res?.data?.data });
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };

  const handelGetUserDetails = async () => {
    await axios
      .get("userdetails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updateConfigs({
          ...res.data.data,
          userid: res?.data?.data?._id,
        });
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };

  const cartTotalQuantity = React?.useMemo(() => {
    return carts.reduce((total, item) => total + item.qty, 0);
  }, [carts]); // Only recalculates if cartData changes

  useEffect(() => {
    if (isAuth && token) {
      (async () => {
        await getUserCartData();
      })();
    }
  }, [isAuth, token, cartTotalQuantity]); //  load the card data on the login

  useEffect(() => {
    if (isAuth && token) {
      (async () => {
        await handelGetUserDetails();
      })();
    }
  }, [isAuth, token]); //  load the User Credentials

  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer
        position="top-right"
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <LoginModal />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    configs: state?.configs,
    token: state?.configs?.token,
    carts: state?.cartList?.cart,
    wish: state?.wishList?.w_list,
    compare: state?.compareList?.compare_list,
  };
};
export default connect(mapStateToProps, {
  updateConfigs,
  addUserCart,
  logout,
})(App);
