import React from "react";
import Logo from "../UiStyle/Logo";
import SmallLogo from "../UiStyle/Logo/SmallLogo";

const SectionTitle = (props) => {
  return (
    <div className={`heading-title ${props.sClass}`}>
      <h2>
        <i>
          {/* <Logo className="h-35" /> */}
          <SmallLogo className="h-35" />
        </i>
        {props.sTitle}
      </h2>
    </div>
  );
};

export default SectionTitle;
