import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Button, Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { checkLoggedIn, getDeliveryCharges, totalCartPrice } from "../../utils";
import { Tooltip } from "react-tooltip";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  addUserCart,
} from "../../store/actions/action";
import Footer from "../../components/footer/Footer";
import DeleteIcon from "../../icons/DeleteIcon";
import Recomended from "../../components/Recomended/Recomended";
import Categorys from "../../components/Categorys/Categorys";
import emptyCart from "../../images/empty-cart.png";
import axios from "axios";
import {
  ShimmerCategoryList,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { PulseLoader } from "react-spinners";
import CartProductRow from "./CartProductRow";
import { useLoginModal } from "../../Context/LoginModalContext";
import { toast } from "react-toastify";
import { useCart } from "../../Context/CartContext";
import { fCurrency } from "../../utils/formatNumber";

const CartPage = (props) => {
  const { carts, token, configs } = props;

  console.log(
    "🚀 ~ CartPage ~ delivery_charges:",
    configs?.admin_conditions?.delivery_charges
  );
  const { isAuth } = configs;
  const { openLoginModal } = useLoginModal();
  const [open, setOpen] = useState(false);
  const delivery_charges = useSelector(
    (state) => state?.configs?.admin_conditions?.delivery_charges
  );
  const loggedInCartPrice = useSelector((state) => state?.cartList?.totalPrice);
  const totalPrice = checkLoggedIn()
    ? loggedInCartPrice || 0
    : (carts?.length > 0 ? totalCartPrice(carts) : 0) + delivery_charges || 1;

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const [coupon, setCoupon] = useState("");
  const handleChange = (event) => {
    setCoupon(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { cart } = useCart();
  console.log("🚀 ~ CartPage ~ cart:", cart);

  const getUserCartData = async () => {
    setLoading(true);
    if (error) setError(false);
    await axios
      .get("get-cart", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        props?.addUserCart({
          cart: res?.data?.cartDetails,
          totalPrice: res?.data?.totalPrice,
          totalQuantity: res?.data?.totalQuantity,
        });
        setLoading(false);
      })
      .catch((err) => {
        props?.addUserCart({
          cart: [],
          totalPrice: "",
          totalQuantity: "",
        });
        // toast.error(err?.response?.data?.message);
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (checkLoggedIn()) getUserCartData();
  }, [isAuth]);

  return (
    <Fragment>
      <Navbar />
      {/* <PageTitle pageTitle={"Cart"} pagesub={"Cart"} /> */}

      <div className="cart-area sm-separator-padding">
        <div className="sm-container">
          {/* Loading state */}
          {loading ? (
            <ShimmerCategoryList items={4} categoryStyle="STYLE_SEVEN" />
          ) : carts?.length === 0 ? (
            // Empty cart state
            <div className="form">
              <div
                className="cart-wrapper flex justify-center items-center border mb-40 row"
                style={{ borderRadius: "20px" }}
              >
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                  <img src={emptyCart} alt="cart-images" />
                </div>
                <div className="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-12">
                  <div className="heading-title">
                    <h2>Your Cart Is Empty</h2>
                    <p>Start Adding Items to your Cart</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="heading-title">
                  <h2 className="mb-10">Featured Category</h2>
                </div>
                <Categorys
                  showTitle={false}
                  className={"p-0 m-0 pl-0"}
                  innerClassName={"p-0 m-0 pl-0"}
                  {...props}
                />
              </div>
              <div>
                <div className="heading-title mt-20">
                  <h2>Recommended</h2>
                </div>
                <Recomended />
              </div>
            </div>
          ) : (
            // Cart items display
            <div className="form">
              <div className="cart-wrapper">
                <div className="heading-title">
                  <h2>Cart</h2>
                </div>
                <div className="row">
                  <form className="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-12">
                    <table className="table-responsive cart-wrap">
                      <thead>
                        <tr>
                          <th className="product-2">Product</th>
                          <th className="pr">Price</th>
                          <th className="quantity">Quantity</th>
                          <th className="subtotal">Subtotal</th>
                          <th className="actions">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carts?.map((cartItem, crt) => (
                          <CartProductRow
                            cartItem={cartItem}
                            key={crt}
                            {...props}
                          />
                        ))}
                      </tbody>
                    </table>
                  </form>
                  {/* Cart summary */}
                  <div className="cart-product-list col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                    {cart?.loading ? (
                      <ShimmerTitle line={4} gap={30} />
                    ) : (
                      <ul>
                        <li>
                          Subtotal
                          <span>
                            $
                            {fCurrency(
                              totalPrice -
                                Number(
                                  configs?.admin_conditions?.delivery_charges
                                )
                            )}
                          </span>
                        </li>
                        <li>
                          Delivery Charge<span>${getDeliveryCharges()}</span>
                        </li>
                        <li className="cart-b">
                          Total Price
                          <span>${fCurrency(totalPrice)}</span>
                        </li>
                        <li>
                          {checkLoggedIn() === true ? (
                            <Link className="c-btn" to="/checkout">
                              <i className="icon-privacy"></i> Proceed to
                              Checkout
                            </Link>
                          ) : (
                            <Link
                              className="c-btn"
                              to="#"
                              onClick={() => {
                                openLoginModal();
                              }}
                            >
                              <i className="icon-privacy"></i> Proceed to
                              Checkout
                            </Link>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                {/* Coupon section */}
                {/* <div className="coupon-area mt-30">
                  <div className="coupon-box" onClick={handleToggle}>
                    <p>
                      <i className="icon-icon-check"></i>Have a coupon?{" "}
                      <span className="coupon-click">
                        Click here to enter your code.
                      </span>
                    </p>
                  </div>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="coupon-form">
                      <span>If you have a coupon code, please apply it</span>
                      <div className="input-wrap">
                        <input
                          type="text"
                          value={coupon}
                          onChange={handleChange}
                        />
                        <button>Apply</button>
                      </div>
                    </div>
                  </Collapse>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    configs: state.configs,
    token: state.configs?.token,
  };
};

export default connect(mapStateToProps, {
  addUserCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
})(CartPage);
