import { has } from "lodash";
import { store } from "../store/index";
import axios from "axios";
import classNames from "classnames";
import { Navigate } from "react-router-dom";

function getFlashProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 8);
}

function getFeaturedProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 12);
}

function totalCartPrice(items) {
  return items.reduce((itemAcc, item) => {
    return (itemAcc += item.price * item.qty);
  }, 0);
}

function isWishListed(productId, wishList) {
  return wishList.findIndex((product) => product.id === productId) !== -1;
}

function getCompareList(items) {
  return items.slice(0, 4);
}

function searchFilter(row, search) {
  return row.title.toLowerCase().includes(search.toLowerCase()) || !search;
}

// short helper function
function checkLengNull(data) {
  if (data !== null) {
    return data.length > 0;
  }
  return false;
}

function isEquals(a, b) {
  if (a !== null && b !== null) {
    return a.toLowerCase() === b.toLowerCase();
  }
  return a === b;
}

function minValueOne(qty) {
  if (qty < 1) {
    return 1;
  }
  return qty;
}

export const RemoveEmptyValues = (filters) => {
  const cleaned = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      // Check for non-empty and consider 0 as valid
      cleaned[key] = filters[key];
    }
  });
  return cleaned;
};

// filter function
function filterProductByCategory(product, selected_category) {
  if (checkLengNull(selected_category)) {
    return product.category.toLowerCase() === selected_category.toLowerCase();
  }
  return true;
}

function filterProductByPrice(product, price) {
  if (checkLengNull(price)) {
    return product.price >= price[0] && product.price <= price[1];
  }
  return true;
}

function filterProductByColor(product, color) {
  if (checkLengNull(color)) {
    for (var i = 0; i < product.colors.length; i++) {
      if (product.colors[i].toLowerCase() === color.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function filterProductBySize(product, size) {
  if (checkLengNull(size)) {
    for (var i = 0; i < product.size.length; i++) {
      if (product.size[i].toLowerCase() === size.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export const checkLoggedIn = () => {
  let isAuth = store.getState()?.configs?.isAuth;
  let token = store.getState()?.configs?.token;

  if (!isAuth || !token) {
    return false;
  } else {
    return true;
  }
};

export const getDeliveryCharges = () => {
  const DEFAULT_DELIVERY_PRICE = 50;
  const deliveryCharges =
    store.getState()?.configs?.admin_conditions?.delivery_charges;
  return deliveryCharges ? deliveryCharges : DEFAULT_DELIVERY_PRICE;
};

const getBaseURL = () => {
  return process.env.REACT_APP_BASE_URL;
  // return "http://localhost:5000/";
};

const callApi = ({
  method = "GET",
  url = null,
  endpoint = "",
  type = "json",
  headers = {},
  data = {},
  additionalProps = {},
  alert = false,
  useToken = true,
} = {}) => {
  // retruning a promise to resolve
  return new Promise((resolve, reject) => {
    //get auth token
    let token = useToken ? store.getState()?.auth?.token : null;
    // header based on different endpoint calls
    let headerTypes = {
      json: { "content-type": `application/json` },
      formdata: { "content-type": `multipart/form-data` },
    };

    try {
      //creation of default data for the endpoint call
      let defaultData = {
        method: method.toUpperCase(),
        url: url ? url : getBaseURL() + "/" + endpoint,
        headers: {
          ...headerTypes[type],
          ...headers,

          Authorization: has(headers, "Authorization")
            ? headers.Authorization
            : "Bearer " + token,
        },
        data: data,
        ...additionalProps,
      };

      //axios call
      axios(defaultData)
        .then((res) => {
          if (alert) {
            // Alert(res.data.message);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          //if user cancels the request in between

          if (has(additionalProps, "cancelToken")) {
            if (axios.isCancel(err)) {
              if (alert) {
                Error(err.message);
              }
              reject(err);
            }
          }

          // other than server errors
          else {
            if (alert) {
              Error(err.response.data.message);
            }
          }

          reject(err.response?.data?.error);
        });
    } catch (e) {
      Error("NETWORK ERROR");
      reject(e.toString());
    }
  });
};

const mergeClasses = (...classes) => {
  return classNames(...classes);
};

export {
  getFlashProducts,
  getFeaturedProducts,
  totalCartPrice,
  isWishListed,
  filterProductByCategory,
  filterProductByPrice,
  filterProductByColor,
  filterProductBySize,
  isEquals,
  minValueOne,
  getCompareList,
  searchFilter,
  getBaseURL,
  callApi,
  mergeClasses,
};
