import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import sImg from "../../images/best-selling/right-side-img.png";
import axios from "axios";
import { toast } from "react-toastify";
import HorizontalLoadingCard from "../Cards/HorizontalLoadingCard";
import { Pagination, PaginationItem } from "@mui/material";
import HorizontalProductCard from "../Cards/HorizontalProductCard";
import CustomPaginationButton from "../UI/Button/CustomPaginationButton";

const TopProducts = ({
  products,
  addToCartProduct,
  addToWishListProduct,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const [topProducts, setTopProducts] = useState([]);
  const [state, setState] = useState({});
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 12,
    totalRecords: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };

  const handlePageChange = (event, value) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const fetchTopProducts = () => {
    setLoading(true);
    if (error) setError(false);
    axios
      .get("get-products", {
        params: { topproducts: true, ...filter },
      })
      .then((response) => {
        const data = response?.data?.data;
        setTopProducts(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setTopProducts([]);
        setError(true);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTopProducts();
  }, [filter.page]);

  return (
    <section className="best-seller-section sm-separator-padding mt-0 pt-0">
      <div className="sm-container sortable-gallery">
        <SectionTitle
          sTitle={"Top Product"}
          sClass={"heading-title-s3 mb-10 mt-10"}
        />

        <div className="row">
          <div className="col-lg-12 ">
            {loading ? (
              <>
                <div className="recommended-grid-3 w-full">
                  {new Array(filter?.limit || 8).fill(1)?.map((el) => {
                    return <HorizontalLoadingCard />;
                  })}
                </div>
                <div className="flex-justify-center-items-center d-block d-md-none h-45"></div>
              </>
            ) : topProducts.length === 0 ? (
              <div>
                <h3>Oops! No Data Found!</h3>
              </div>
            ) : (
              <div className="product-wrap relative">
                <div className="recommended-grid-3">
                  {topProducts?.length > 0 && (
                    <>
                      {topProducts?.map((product, index) => (
                        <HorizontalProductCard product={product} />
                      ))}
                    </>
                  )}
                </div>
                {topProducts?.length > 0 && (
                  <div className="flex-justify-center-items-center mt-10">
                    <Pagination
                      count={Math.ceil(filter?.totalRecords / filter?.limit)}
                      size={"large"}
                      page={filter?.page}
                      defaultPage={filter?.page}
                      onChange={handlePageChange}
                      siblingCount={0}
                      renderItem={(item) => {
                        return <CustomPaginationButton item={item} />;
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="col-xl-3 col-lg-5">
            <div className="best-selling-right-sidebar">
              <div className="right-sidebar-text">
                <div className="small-heading-title">
                  <span>Popular Healthcare</span>
                  <h3>Eye Testing Machine</h3>
                  <Link
                    // onClick={() => handleCategoryClick(category?.name)}
                    to={"/shop"}
                    className="small-btn-style"
                  >
                    Shop Now
                  </Link>
                </div>
              </div>
              <div className="left-sidebar-img">
                <img src={sImg} alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default TopProducts;
