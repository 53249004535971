import axios from "axios";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const DEFAULT_LIMIT = 10;
// Create the context
const SearchModalContext = createContext();

// Export a custom hook for consuming the context
export const useSearchModal = () => useContext(SearchModalContext);

// Context provider component
export const SearchModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchFilter, setSearchFilter] = useState({
    page: 1,
    limit: DEFAULT_LIMIT,
    totalRecords: null,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Wrapped searchProductsFunc with debounce
  const searchProductsFunc = useCallback(
    debounce(async () => {
      setSearchLoading(true);
      if (searchError) setSearchError(false);
      console.log("🚀 ~ debounce ~ searchFilter:", searchFilter?.page);
      try {
        const res = await axios.get("get-products", {
          params: { title: searchQuery, ...searchFilter },
        });
        const data = res?.data?.data?.map((el) => ({ ...el, id: el._id }));
        setSearchedProducts(data);
        setSearchFilter((prev) => ({
          ...prev,
          totalRecords: res.data.totalRecords,
        }));
        setSearchLoading(false);
      } catch (err) {
        setSearchLoading(false);
        setSearchedProducts([]);
        setSearchError(true);
        toast.error(err?.data?.message);
      }
    }, 500),
    [searchQuery, searchFilter?.page, searchFilter?.limit]
  );

  useEffect(() => {
    if (searchQuery) {
      searchProductsFunc();
    } else if (searchQuery.length === 0) {
      setSearchedProducts([]);
      setSearchFilter({
        page: 1,
        limit: DEFAULT_LIMIT,
        totalRecords: null,
      });
      setSearchLoading(false);
      setSearchError(false);
    }
    return searchProductsFunc.cancel; // Clean up debounce on unmount
  }, [searchQuery, searchProductsFunc]);

  useEffect(() => {
    if (searchFilter.page > 1) {
      searchProductsFunc();
    }
  }, [searchFilter.page]);

  let valueData = {
    isModalOpen,
    setIsModalOpen,
    searchQuery,
    setSearchQuery,
    searchLoading,
    setSearchLoading,
    searchError,
    setSearchError,
    searchedProducts,
    setSearchedProducts,
    openModal,
    closeModal,
    searchProductsFunc,
    searchFilter,
    setSearchFilter,
    handleSearchPageChange: (event, value) => {
      setSearchFilter((prev) => ({ ...prev, page: value }));
    },
  };

  return (
    <SearchModalContext.Provider value={valueData}>
      {children}
    </SearchModalContext.Provider>
  );
};
