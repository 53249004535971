import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { store } from "../store";
import { addConfigs } from "../store/actions/action";

const ProductsContext = createContext();

export const useProduct = () => useContext(ProductsContext);

export const ProductsProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState(false);

  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brandsError, setBrandsError] = useState(false);

  const getCategories = async () => {
    setCategoriesLoading(true);
    if (categoriesError) setCategoriesError(false);
    await axios
      .get("categories")
      .then((res) => {
        let data = res?.data?.data?.map((el) => ({
          name: el?.name,
          url: el?.url,
        }));
        store.dispatch(addConfigs({ categories: data }));
        setCategoriesLoading(false);
      })
      .catch((err) => {
        setCategoriesLoading(false);
        setCategoriesError(true);
        toast.error(err?.data?.message);
      });
  };

  const getBrands = async () => {
    setBrandsLoading(true);
    if (categoriesError) setBrandsError(false);
    await axios
      .get("brand")
      .then((res) => {
        let data = res?.data?.data?.map((el) => el.name);
        store.dispatch(addConfigs({ brand: data }));
        setBrandsLoading(false);
      })
      .catch((err) => {
        setBrandsLoading(false);
        setBrandsError(true);
        toast.error(err?.data?.message);
      });
  };

  useEffect(() => {
    getCategories();
    getBrands();
  }, []);

  let valueData = {
    // categories,
    // setCategories,
    // categoriesLoading,
    // setCategoriesLoading,
    // categoriesError,
    // setCategoriesError,
    // brands,
    // setBrands,
    // brandsLoading,
    // setBrandsLoading,
    // brandsError,
    // setBrandsError,
    // getCategories,
    // getBrands,
  };

  return (
    <ProductsContext.Provider value={valueData}>
      {children}
    </ProductsContext.Provider>
  );
};
