import React from "react";
import ReactDOM from "react-dom/client";
import App from "../src/main-component/App/App";
import reportWebVitals from "./reportWebVitals";
import { ParallaxProvider } from "react-scroll-parallax";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/icomoon.css";
import "./css/themify-icons.css";
import "./css/animate.css";
import "./sass/style.scss";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/index";
import { Provider } from "react-redux";
import axios from "axios";
import { ProductsProvider } from "./Context/ProductsContext";
import { SearchModalProvider } from "./Context/SearchModalContext";
import { LoginModalProvider } from "./Context/LoginModalContext";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartProvider } from "./Context/CartContext";
import ScrollToTop from "./utils/ScrollToTop";

axios.defaults.baseURL = "https://apis.chemistforall.com/v1/auth";
// axios.defaults.baseURL = "http://localhost:5000/v1/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ParallaxProvider>
            <CartProvider>
              <LoginModalProvider>
                <ProductsProvider>
                  <SearchModalProvider>
                    <ScrollToTop />
                    <App />
                  </SearchModalProvider>
                </ProductsProvider>
              </LoginModalProvider>
            </CartProvider>
          </ParallaxProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
