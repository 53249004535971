import React from 'react'
import abImg from '../../images/about/img-3.png'
import SectionTitle from '../SectionTitle/SectionTitle';


const AboutHistory = (props) => {

    return (
        <div className="about-history-section separator-padding pb-0">
            <div className="container">
                <div className="history-top">
                    <div className="row">
                        <div className="col-lg-8">
                            <SectionTitle sTitle={'Chemist For All Company History'} />
                            <p>I have been a loyal customer of this auto parts company for years and I cannot recommend them enough. Their extensive selection of high-quality parts and accessories, combined with their competitive prices and exceptional customer service, make them the go-to source for all of my automotive needs</p>
                        </div>
                    </div>
                </div>
                <div className="history-wrap">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="history-img">
                                <img src={abImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 offset-lg-1">
                            <div className="history-items">
                                <div className="history-item">
                                    <h3><i className="icon-medicine"></i>Chemist For All Company</h3>
                                    <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centu ries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                </div>
                                <div className="history-item">
                                    <h3><i className="icon-balanced-diet"></i>Online pharmacy</h3>
                                    <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centu ries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHistory;