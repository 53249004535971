import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingCard from "../Cards/LoadingCard";
import { useNavigate } from "react-router-dom";
import { useSearchModal } from "../../Context/SearchModalContext";
import HorizontalProductCard from "../Cards/HorizontalProductCard";
import { mergeClasses } from "../../utils";
import { Pagination } from "@mui/material";
import CustomPaginationButton from "../UI/Button/CustomPaginationButton";
import HorizontalLoadingCard from "../Cards/HorizontalLoadingCard";

const Recomended = ({
  addToCartProduct,
  addToWishListProduct,
  className,
  ...props
}) => {
  const [recomended, setRecomended] = useState([]);
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 6,
    totalRecords: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlePageChange = (event, value) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const { closeModal } = useSearchModal();

  const fetchRecomendedcategories = () => {
    setLoading(true);
    if (error) setError(false);
    axios
      .get("get-recommended", {
        params: { recommended: true, ...filter },
      })
      .then((response) => {
        const data = response?.data?.data;
        setRecomended(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setRecomended([]);
        setError(true);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRecomendedcategories();
  }, [filter.page]);

  const ClickHandler = (product) => {
    closeModal();
    navigate(`/product-single/${product.slug}`);
  };

  return (
    <section className={mergeClasses("recomended-section", className)}>
      <div>
        {loading ? (
          <>
            <div className="recommended-grid-3 w-full">
              {new Array(6).fill(1)?.map((el) => {
                return <HorizontalLoadingCard />;
              })}
            </div>
            <div className="w-full d-block d-md-none h-45"></div>
          </>
        ) : recomended.length === 0 ? (
          <div>
            <h3>Oops! No Data Found!</h3>
          </div>
        ) : (
          <div className="product-wrap relative">
            <div className="recommended-grid-3">
              {recomended?.length > 0 && (
                <>
                  {recomended?.map((product, index) => (
                    <HorizontalProductCard product={product} />
                  ))}
                </>
              )}
            </div>
            {recomended?.length > 0 && (
              <div className="flex-justify-center-items-center mt-10">
                <Pagination
                  count={Math.ceil(filter?.totalRecords / filter?.limit)}
                  size={"large"}
                  page={filter?.page}
                  defaultPage={filter?.page}
                  onChange={handlePageChange}
                  siblingCount={0}
                  renderItem={(item) => {
                    return <CustomPaginationButton item={item} />;
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Recomended;
