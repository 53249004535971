import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Discountbar from "../Discountbar/Discountbar";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import HeaderMiddle from "../HeaderMiddle/HeaderMiddle";
import SearchModal from "../Modal/SearchModal";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { logout } from "../../store/actions/action";
import LogoutIcon from "../../icons/LogoutIcon";
import LoginIcon from "../../icons/LoginIcon";
import UserIcon from "../../icons/UserIcon";
import logoutImage from "../../images/log-out.jpg";

const Header = (props) => {
  const [categoryActive, setcategoryState] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryParam = searchParams?.get("category");

  const isHome = location?.pathname === "/";
  const isShop = location?.pathname === "/shop";
  const isOrders = location?.pathname === "/your-orders";

  const openLogoutModal = () => {
    setLogoutModal(true);
  };
  const closeLogoutModal = () => {
    setLogoutModal(false);
  };
  const { configs } = props;
  const { isAuth, token } = configs;

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const handleCategoryClick = (category) => {
    // Create URLSearchParams object
    const searchParams = new URLSearchParams();
    searchParams.set("category", category);

    // Navigate to the new route with the updated query params
    navigate(`/shop?${searchParams.toString()}`);
    setcategoryState(false);
  };

  const activeItemRef = useRef(null);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [categoryParam, categoryActive]);

  return (
    <>
      <div className="main-header-wrap">
        {/* <Discountbar />
        <HeaderTopbar /> */}

        <header className={`header-area ${props.hclass}`}>
          <HeaderMiddle />
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-6">
                    <div className="header-category-item">
                      <button
                        className="header-category-toggle-btn"
                        onClick={() => setcategoryState(!categoryActive)}
                      >
                        <span>Shop By Category</span>{" "}
                      </button>
                      {/* WARPPER FOR CLICK OUTSIDE THE header-category-item */}
                      {categoryActive && (
                        <div
                          onClick={(event) => {
                            event?.stopPropagation();
                            setcategoryState(false);
                          }}
                          id="header-category-item"
                          style={{
                            backgroundColor: "transparent",
                            position: "fixed",
                            height: "100vh",
                            width: "100vw",
                            zIndex: 10,
                            top: 0,
                            right: 0,
                          }}
                        />
                      )}
                      <div
                        className={`category-shop-item ${
                          categoryActive ? "category-shop-item-toggle" : ""
                        }`}
                      >
                        <ul id="metis-menu">
                          {categoryActive &&
                            props?.configs?.categories?.map((cat) => {
                              const isActive = categoryParam === cat?.name;
                              return (
                                <li
                                  onClick={() => handleCategoryClick(cat?.name)}
                                  style={
                                    isActive
                                      ? { backgroundColor: "#F3A952" }
                                      : {}
                                  }
                                  ref={isActive ? activeItemRef : null}
                                >
                                  <Link to="#">
                                    <img
                                      src={cat?.url}
                                      className="sm-icon-image"
                                      alt="sm-icon-img"
                                    />
                                    <p className="break-word m-0 ms-2">
                                      {cat?.name}
                                    </p>
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                        {/* <ul id="metis-menu">
                          <li>
                            <Link onClick={ClickHandler} to="/shop">
                              <i className="icon-mask-1"></i> Surgical Mask
                            </Link>
                          </li>
                          <li className="header-catagory-item">
                            <Link className="menu-down-arrow" to="/shop">
                              <i className="icon-medicine"></i>
                              Pharmacy
                            </Link>
                            <ul className="header-catagory-single">
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine & Equipment
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Thermometer
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medical Supplies
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/shop">
                              <i className="icon-balanced-diet"></i> Nutritions
                            </Link>
                          </li>
                          <li className="header-catagory-item">
                            <Link className="menu-down-arrow" to="/shop">
                              <i className="icon-medical"></i>
                              Medkits
                            </Link>
                            <ul className="header-catagory-single">
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine & Equipment
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Thermometer
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medical Supplies
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/shop">
                              <i className="icon-medicine2"></i> Medicine
                            </Link>
                          </li>
                          <li className="header-catagory-item">
                            <Link className="menu-down-arrow" to="/shop">
                              <i className="icon-skincare"></i> Beauty Product
                            </Link>
                            <ul className="header-catagory-single">
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine & Equipment
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Thermometer
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medical Supplies
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="header-catagory-item">
                            <Link className="menu-down-arrow" to="/shop">
                              <i className="icon-stethoscope"></i>
                              Equipments
                            </Link>
                            <ul className="header-catagory-single">
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medicine & Equipment
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Thermometer
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/shop">
                                  Medical Supplies
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/shop">
                              <i className="icon-medicine"></i> Medical Supplies
                            </Link>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/shop">
                              <i className="icon-food"></i> Grocery Product
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-1 col-1 text-right text-xl-right d-none d-lg-block">
                    <div className="main-menu">
                      <nav id="mobile-menu">
                        <ul className="nav">
                          <li>
                            <Link
                              className={isHome ? "selected-route" : ""}
                              onClick={ClickHandler}
                              to="/"
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.07926 0.222253C7.31275 -0.007434 7.6873 -0.007434 7.92079 0.222253L14.6708 6.86227C14.907 7.09465 14.9101 7.47453 14.6778 7.71076C14.4454 7.947 14.0655 7.95012 13.8293 7.71773L13 6.90201V12.5C13 12.7761 12.7762 13 12.5 13H2.50002C2.22388 13 2.00002 12.7761 2.00002 12.5V6.90201L1.17079 7.71773C0.934558 7.95012 0.554672 7.947 0.32229 7.71076C0.0899079 7.47453 0.0930283 7.09465 0.32926 6.86227L7.07926 0.222253ZM7.50002 1.49163L12 5.91831V12H10V8.49999C10 8.22385 9.77617 7.99999 9.50002 7.99999H6.50002C6.22388 7.99999 6.00002 8.22385 6.00002 8.49999V12H3.00002V5.91831L7.50002 1.49163ZM7.00002 12H9.00002V8.99999H7.00002V12Z"
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                              Home Page
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={isShop ? "selected-route" : ""}
                              onClick={ClickHandler}
                              to="/shop"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
                                />
                              </svg>
                              Shop
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={isOrders ? "selected-route" : ""}
                              to="/your-orders"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                width="18"
                                height="18"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                              Your Orders
                            </Link>
                          </li>
                          {/* <li className="has-submenu">
                            <Link onClick={ClickHandler} to="/about">
                              Pages
                            </Link>
                            <ul className="sub-menu">
                              <li>
                                <Link onClick={ClickHandler} to="/about">
                                  About
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/login">
                                  My Account
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/forgot">
                                  Forgot Password
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/contact">
                                  Contact
                                </Link>
                              </li>
                              <li>
                                <Link onClick={ClickHandler} to="/404">
                                  404
                                </Link>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li className="has-submenu">
                            <Link onClick={ClickHandler} to="/home">
                              Blog
                            </Link>
                            <ul className="sub-menu">
                              <li>
                                <Link onClick={ClickHandler} to="/blog">
                                  Blog Grid
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to="/blog-right-sidebar"
                                >
                                  Blog right sidebar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to="/blog-left-sidebar"
                                >
                                  Blog left sidebar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to="/blog-fullwidth"
                                >
                                  Blog fullwidth
                                </Link>
                              </li>
                              <li className="third-lavel-menu">
                                <Link
                                  onClick={ClickHandler}
                                  to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response"
                                >
                                  Blog Single
                                </Link>
                                <ul className="sub-menu">
                                  <li>
                                    <Link
                                      onClick={ClickHandler}
                                      to="/blog-single/Mirage-Deep-Dive-Under-anding-Timin-Response"
                                    >
                                      Blog single right sidebar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={ClickHandler}
                                      to="/blog-single-left-sidebar/Mirage-Deep-Dive-Under-anding-Timin-Response"
                                    >
                                      Blog single left sidebar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={ClickHandler}
                                      to="/blog-single-fullwidth/Mirage-Deep-Dive-Under-anding-Timin-Response"
                                    >
                                      Blog single fullwidth
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={ClickHandler} to="/contact">
                              Contact
                            </Link>
                          </li> */}
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-md-1 col-1 text-left">
                    <div className="header-area-right">
                      {isAuth && token ? (
                        <div className="flex items-center gap-2">
                          <div className="profile">
                            <Link to="#" onClick={openLogoutModal}>
                              <button className="logout-button">
                                <LogoutIcon />
                                <span className="ml-4">
                                  <small
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "100px",
                                    }}
                                  >
                                    {configs?.fullname}
                                  </small>
                                  <span>log-out </span>
                                </span>
                              </button>
                            </Link>
                          </div>
                          <div className="profile">
                            <Link to="/user-profile">
                              <button className="profile-button">
                                <UserIcon />
                              </button>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="profile">
                          <Link onClick={ClickHandler} to="/login">
                            <button className="logout-button">
                              <LoginIcon />
                              <span className="ml-4">
                                <small>My Account</small>
                                <span>Hello, Sign in </span>
                              </span>
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-5 col-sm-6 col-md-6 col-xl-6">
                    <div className="flex justify-end">
                      <MobileMenu
                        isAuth={isAuth}
                        token={token}
                        openLogoutModal={openLogoutModal}
                        configs={configs}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <SearchModal />
      <Dialog
        open={logoutModal}
        onClose={closeLogoutModal}
        maxWidth={"lg"}
        sx={{ "& .MuiDialog-paper": { minWidth: "300px" } }}
      >
        <DialogTitle id="scroll-dialog-title">
          <h4 className="mt-14">Confirm Logout</h4>
          <button
            className="btn btn-close mt-10"
            style={{ right: "22px" }}
            onClick={closeLogoutModal}
          >
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent className="login-form mt-0">
          <div className="flex justify-center items-center w-full">
            <img
              height={"200px"}
              width={"200px"}
              src={logoutImage}
              alt="logout-image"
            />
          </div>
          <div className="w-full flex flex-col gap-3 pt-0">
            <button
              className="btn border"
              style={{ minHeight: "40px" }}
              onClick={closeLogoutModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              style={{
                backgroundColor: "#F3A952",
                border: "none",
                minHeight: "40px",
              }}
              onClick={() => {
                props?.logout();
                closeLogoutModal();
              }}
            >
              Logout
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps, { logout })(Header);
