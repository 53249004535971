import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { connect } from "react-redux";
import {
  addToCart,
  addToWishList,
  addToCompareList,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../store/actions/action";
import Hero from "../../components/hero/hero";
import AnimateMarquee from "../../components/AnimateMarquee/AnimateMarquee";
import Categorys from "../../components/Categorys/Categorys";
import OfferBanner from "../../components/OfferBanner/OfferBanner";
import PopularCategories from "../../components/PopularCategories/PopularCategories";
import CtaBanner from "../../components/CtaBanner/CtaBanner";
import LatestProduct from "../../components/LatestProduct/LatestProduct";
import CtaBannerS2 from "../../components/CtaBannerS2/CtaBannerS2";
import DealProduct from "../../components/DealProduct/DealProduct";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import DailyBestSelling from "../../components/DailyBestSelling/DailyBestSelling";
import CtaBannerS3 from "../../components/CtaBannerS3/CtaBannerS3";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import BestSeller from "../../components/TopAndBestProduct/BestSellers";
import TopProducts from "../../components/TopAndBestProduct/TopProducts";
import Recomended from "../../components/Recomended/Recomended";

const HomePage = ({ addToCart, addToWishList, addToCompareList, ...props }) => {
  const addToCartProduct = async (product, qty = 1) => {
    await addToCart(product, qty);
  };
  const addToWishListProduct = (product, qty = 1) => {
    addToWishList(product, qty);
  };
  const addToCompareListProduct = (product, qty = 1) => {
    addToCompareList(product, qty);
  };

  return (
    <Fragment>
      <Navbar />
      <Hero />
      {/* <AnimateMarquee /> */}
      <Categorys {...props} />
      {/* <Categorys3 {...props} /> */}
      {/* <OfferBanner /> */}
      <PopularCategories
        addToCartProduct={addToCartProduct}
        addToWishListProduct={addToWishListProduct}
        addToCompareListProduct={addToCompareListProduct}
        {...props}
      />
      {/* <CtaBanner /> */}
      <LatestProduct
        addToCartProduct={addToCartProduct}
        addToWishListProduct={addToWishListProduct}
        addToCompareListProduct={addToCompareListProduct}
        {...props}
      />
      {/* <CtaBannerS2 /> */}
      <DealProduct
        addToCartProduct={addToCartProduct}
        addToWishListProduct={addToWishListProduct}
        addToCompareListProduct={addToCompareListProduct}
        {...props}
      />
      {/* <TopAndBestProduct products={products} /> */}
      <BestSeller {...props} />
      <TopProducts {...props} />
      <FeaturesSection />
      {/* <DailyBestSelling
        addToCartProduct={addToCartProduct}
        addToWishListProduct={addToWishListProduct}
        addToCompareListProduct={addToCompareListProduct}
        {...props}
      /> */}

      {/* <PartnerSection /> */}
      {/* <Testimonial /> */}
      {/* <CtaBannerS3 /> */}
      <div className="sm-container mb-20">
        <h2>Recomended</h2>
        <Recomended
          addToCartProduct={addToCartProduct}
          addToWishListProduct={addToWishListProduct}
          addToCompareListProduct={addToCompareListProduct}
          {...props}
        />
      </div>
      {/* <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps, {
  addToCart,
  addToWishList,
  addToCompareList,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
})(HomePage);
