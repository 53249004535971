import React from "react";
import { styled } from "@mui/material/styles";
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from "@mui/material";
console.log("🚀 ~ stepConnectorClasses:", stepConnectorClasses);

// Custom Connector with dynamic colors
const QontoConnector = styled(StepConnector)(
  ({ theme, activeBorderColor, color }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: activeBorderColor || color, // Active connector color
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: activeBorderColor || color, // Completed connector color
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        borderColor: theme.palette.grey[800],
      }),
    },
  })
);

// Custom Step Icon with dynamic color support
const QontoStepIconRoot = styled("div")(
  ({ theme, color, activeColor, ...props }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    "& .QontoStepIcon-completedIcon": {
      color: color,
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#d3d3d3",
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: activeColor || color, // Apply active color if active
        },
      },
    ],
  })
);

function QontoStepIcon(props) {
  const { active, completed, className, color, activeColor } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
      color={color}
      activeColor={activeColor}
    >
      {completed ? (
        <svg
          stroke={color}
          className="mr-10"
          fill={color}
          stroke-width="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
        </svg>
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

// Custom Stepper Component with dynamic props for color, activeColor, activeBorderColor
const CustomStepper = ({
  data,
  activeStep,
  color = "#00d25b",
  activeColor = "#00d25b",
  activeBorderColor = "#00d25b",
}) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={
        <QontoConnector color={color} activeBorderColor={activeBorderColor} />
      }
    >
      {data?.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => (
              <QontoStepIcon
                color={color}
                activeColor={activeColor}
                {...props}
              />
            )}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
