import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

const Feaures = [

  {
    id: "1",
    icon: "icon-shipped",
    title: "Home Delivery",
    des: "Get your medical supplies delivered directly to your door."
  },
//   {
//     id: "2",
//     icon: "icon-product",
//     title: "Free Shipping Over $20",
//     des: "Enjoy free shipping on orders above $20.",
//   },
//   {
//     id: "3",
//     icon: "icon-placeholder",
//     title: "Store Locator",
//     des: "Find a store near you for quick access to medical products.",
//   },
//   {
//     id: "4",
//     icon: "icon-vehicle",
//     title: "Free Servicing",
//     des: "Get free servicing for eligible medical equipment.",
//   },
  {
    id: "5",
    icon: "icon-quality",
    title: "100% Quality Product",
    des: "We guarantee premium quality on all our products.",
  },
  {
    id: "6",
    icon: "icon-shopping-cart",
    title: "Speed Perks",
    des: "Earn rewards with every purchase.",
  },
];

const FeaturesSection = (props) => {
  return (
    <section className="features-section separator-padding">
      <div className="container sortable-gallery">
        <SectionTitle
          sTitle={"Know what you pay for"}
          sClass={"heading-title-s2"}
        />
        <div className="features-wrap">
          <div className="row">
            {Feaures.map((feaures, cat) => (
              <div className="col-lg-4 col-md-6 col-12" key={cat}>
                <div className="features-item">
                  <div className="features-icon">
                    <div className="icon">
                      <i className={feaures.icon}></i>
                    </div>
                  </div>
                  <div className="features-text">
                    <h2>{feaures.title}</h2>
                    <p>{feaures.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
