import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import search from "../../images/icon/search.png";
import { useSearchModal } from "../../Context/SearchModalContext";
import axios from "axios";
import { addUserCart } from "../../store/actions/action";
import { useCart } from "../../Context/CartContext";
import PulseLoader from "react-spinners/PulseLoader";
import MoonLoader from "react-spinners/MoonLoader";
import Logo from "../UiStyle/Logo";
import { useMediaQuery } from "@mui/material";
import { checkLoggedIn, totalCartPrice } from "../../utils";
import { fCurrency } from "../../utils/formatNumber";

const HeaderMiddle = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
    openModal();
  };

  const { carts, wish, configs } = props;
  const { openModal, closeModal, isModalOpen } = useSearchModal();
  const { cart } = useCart();
  const delivery_charges = useSelector(
    (state) => state?.configs?.admin_conditions?.delivery_charges
  );
  const loggedInCartPrice = useSelector((state) => state?.cartList?.totalPrice);
  const totalPrice = checkLoggedIn()
    ? loggedInCartPrice || 0
    : (carts?.length > 0 ? totalCartPrice(carts) : 0) + delivery_charges || 1;

  const [language, setlanguage] = React.useState("");

  const handleChange = (event) => {
    setlanguage(event.target.value);
  };

  const matches = useMediaQuery("(min-width:575px)");

  return (
    <div className="header-middle">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-3">
            <div className="navbar-header">
              <Logo to={"/"} className="navbar-brand" />
            </div>
          </div>

          <div className="col-4 col-sm-4 col-md-5 col-lg-6">
            <form
              onSubmit={SubmitHandler}
              onClick={openModal}
              className={`middle-box border`}
              style={{ opacity: isModalOpen ? 0.4 : 1 }}
            >
              <div className="search-box">
                <div className="input-group">
                  <button className="search-btn" type="submit">
                    <i>
                      <img src={search} alt="" />
                    </i>
                  </button>
                  <p className="mt-14 pl-14">Search Products</p>
                </div>
              </div>
            </form>
          </div>

          <div className="col-5 col-sm-5 col-md-4 col-lg-3">
            <div className="middle-right">
              <ul>
                <li>
                  <Link to="/wishlist">
                    <i className="icon-heart">
                      <span className="cart-count">{wish?.length}</span>
                    </i>
                  </Link>
                </li>
                <li>
                  {cart?.loading ? (
                    <Link to="#" className="relative">
                      <MoonLoader
                        color="#064ACB"
                        size={16}
                        className="absolute"
                        style={{ top: "40%", left: "25%" }}
                      />
                      <i className="icon-cart">
                        <span className="cart-count">
                          <MoonLoader size={8} color="#fafafa" />
                        </span>
                      </i>
                      <p>
                        <small>{carts?.length} items</small>$
                        {fCurrency(totalPrice)}
                      </p>
                    </Link>
                  ) : (
                    <Link to="/cart">
                      <i className="icon-cart">
                        <span className="cart-count">{carts?.length}</span>
                      </i>
                      <p>
                        <small>{carts.length} items</small>$
                        {fCurrency(totalPrice)}
                      </p>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    configs: state?.configs,
    token: state?.configs?.token,
    carts: state?.cartList?.cart,
    wish: state?.wishList?.w_list,
    compare: state?.compareList?.compare_list,
  };
};

export default connect(mapStateToProps, {
  addUserCart,
})(HeaderMiddle);
