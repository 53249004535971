import React from "react";
import Recomended from "../../components/Recomended/Recomended";
import Categorys from "../../components/Categorys/Categorys";

const UserDetails = (props) => {
  const { configs } = props;
  const { fullname, email, country_code, mobile_no, gender, dateOfBirth, _id } =
    configs;

  return (
    <>
      <div
        style={{ backgroundColor: "#1c263a" }}
        className="w-full sm-separator-padding"
      >
        <div className="container flex justify-center items-center p-20 element-shape-background">
          <div className="flex items-center justify-center bg-gray-100 ">
            <div className="bg-white rounded-xl shadow-md p-30 border">
              <div className="flex flex-col items-center">
                <div className="w-24 h-24 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                  {gender ? (
                    gender === "male" ? (
                      <>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          height="3em"
                          width="3em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M511.728 64c108.672 0 223.92 91.534 223.92 159.854v159.92c0 61.552-25.6 179.312-94.256 233.376a63.99 63.99 0 0 0-23.968 57.809c2.624 22.16 16.592 41.312 36.848 50.625l278.496 132.064c2.176.992 26.688 5.104 26.688 39.344l.032 62.464L64 959.504V894.56c0-25.44 19.088-33.425 26.72-36.945l281.023-132.624c20.16-9.248 34.065-28.32 36.769-50.32 2.72-22-6.16-43.84-23.456-57.712-66.48-53.376-97.456-170.704-97.456-233.185v-159.92C287.615 157.007 404.016 64 511.728 64zm0-64.002c-141.312 0-288.127 117.938-288.127 223.857v159.92c0 69.872 31.888 211.248 121.392 283.088l-281.04 132.64S.001 827.999.001 863.471v96.032c0 35.344 28.64 63.968 63.951 63.968h895.552c35.344 0 63.968-28.624 63.968-63.968v-96.032c0-37.6-63.968-63.968-63.968-63.968L681.008 667.439c88.656-69.776 118.656-206.849 118.656-283.665v-159.92c0-105.92-146.64-223.855-287.936-223.855z"></path>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          height="3em"
                          width="3em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M960.032 802.688L681.535 670.624c37.152-18.624 182.256-24.528 194.256-57.28 0 0-57.344-88.016-71.344-202.336-5.44-44.368-14.752-102.592-24-184.592C765.44 93.408 653.567 0 512.257 0h-.513C370.432 0 258.56 93.408 243.568 226.4c-9.248 82-18.56 140.224-24 184.592-14 114.336-71.344 202.336-71.344 202.336 12 32.752 157.088 38.656 194.256 57.28L63.968 802.688S0 825.152 0 878.16v84.528C0 998.064 28.624 1024 63.968 1024h896.064c35.343 0 63.967-25.936 63.967-61.312V878.16c0-53.008-63.967-75.472-63.967-75.472zM63.999 960v-81.84c0-3.408 12.096-11.6 21.936-15.344 2.127-.752 3.44-1.344 5.44-2.32L369.87 728.432c22.128-10.464 36.32-32.687 36.593-57.151.256-24.464-13.44-46.976-35.313-57.936-21.68-10.88-50.336-16.256-95.248-24.032-10.656-1.872-25.216-4.496-39.344-7.312 18.32-41.105 38.56-98.593 46.529-163.633 1.968-16.193 4.496-34.416 7.312-54.592 4.848-34.336 10.848-77.872 16.752-130.224 11.168-98.865 95.28-169.553 204.592-169.553h.512c109.312 0 193.439 70.688 204.592 169.568 5.904 52.336 11.904 95.887 16.752 130.224 2.816 20.176 5.345 38.4 7.312 54.592 7.968 65.024 28.224 122.513 46.528 163.633-14.128 2.816-28.688 5.44-39.344 7.312-44.912 7.776-73.568 13.152-95.248 24.032-21.872 10.976-35.568 33.472-35.313 57.936.289 24.464 14.464 46.687 36.592 57.151l278.496 132.064c2 .976 3.312 1.568 5.44 2.32 9.84 3.744 20.496 11.936 21.936 15.344l.032 81.824H64z"></path>
                        </svg>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <h2 className="text-xl font-semibold text-gray-800">
                  {fullname || "N/A"}
                </h2>
              </div>
              <div className="space-y-3">
                <p>
                  <strong>Email:</strong> {email || "N/A"}
                </p>
                <p>
                  <strong>Mobile:</strong> +{country_code || ""}{" "}
                  {mobile_no || "N/A"}
                </p>
                <p>
                  <strong>Gender:</strong> {gender || "N/A"}
                </p>
                <p>
                  <strong>Date of Birth:</strong> {dateOfBirth || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm-container sm-separator-padding">
        <div className="p-10">
          <div className="heading-title">
            <h2 className="mb-10">Featured Category</h2>
          </div>
          <Categorys
            showTitle={false}
            className={"p-0 m-0 pl-0"}
            innerClassName={"p-0 m-0 pl-0"}
            {...props}
          />
        </div>
        <div className="p-10 pb-20">
          <div className="heading-title mt-20 ">
            <h2>Recommended</h2>
          </div>
          <Recomended />
        </div>
      </div>
    </>
  );
};

export default UserDetails;
