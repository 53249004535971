import React, { Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Pagination,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSearchModal } from "../../Context/SearchModalContext";
import search from "../../images/icon/search.png";
import { ShimmerTitle } from "react-shimmer-effects";
import { toast } from "react-toastify";
import axios from "axios";
import Recomended from "../Recomended/Recomended";
import { connect } from "react-redux";
import {
  addToCart,
  addToCompareList,
  addToWishList,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../store/actions/action";
import LoadingCard from "../Cards/LoadingCard";
import ProductCard from "../Cards/ProductCard";
import ViewModal from "../../components/Modal/index";
import HorizontalProductCard from "../Cards/HorizontalProductCard";
import HorizontalLoadingCard from "../Cards/HorizontalLoadingCard";

const SearchModal = ({
  addToCart,
  addToWishList,
  addToCompareList,
  ...props
}) => {
  const {
    isModalOpen,
    closeModal,
    searchLoading,
    searchError,
    searchProductsFunc,
    searchQuery,
    setSearchQuery,
    searchFilter,
    handleSearchPageChange,
    searchedProducts,
  } = useSearchModal();

  const addToCartProduct = async (product, qty = 1) => {
    await addToCart(product, qty);
  };
  const addToWishListProduct = (product, qty = 1) => {
    addToWishList(product, qty);
  };

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({});

  function handleClose() {
    setOpen(false);
  }

  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:575px)");

  return (
    <Fragment>
      <Dialog
        sx={{
          // Styling for the Dialog Backdrop
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0)", // transparent background
          },

          // Styling for the Dialog Paper
          "& .MuiDialog-paper": {
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)", // subtle shadow
            border: "1px solid #d3d3d3", // light gray border
            position: "absolute",
            top: 50,
            borderRadius: "0px 0px 20px 20px !important", // rounded bottom corners
          },
          // Styling for the MuiDialogContent-root
          "& .MuiDialogContent-root": {
            padding: "0px",
          },

          // Responsive styling based on the screen size
          "& .MuiPaper-root": {
            height: "60vh", // default height
            width: "50vw", // default width
            top: 44, // default top position
            [theme.breakpoints.down("sm")]: {
              height: "60vh",
              width: "80vw", // adjust width on small screens
              top: 20, // adjust position on small screens
            },
            [theme.breakpoints.down("md")]: {
              height: "60vh",
              top: isMobile ? 26 : 40, // conditional top adjustment
            },
            [theme.breakpoints.up("lg")]: {
              height: "74vh", // increase height on large screens
            },
            [theme.breakpoints.up("xl")]: {
              height: "74vh", // consistent height on extra-large screens
            },
          },
        }}
        open={isModalOpen}
        onClose={closeModal} // Close modal on outside click
        maxWidth="sm"
        hideBackdrop={false}
      >
        <DialogTitle id="scroll-dialog-title" className="flex items-center">
          <div className="row w-full pr-20">
            <div className=" col-12">
              <div>
                <div
                  style={{
                    height: "45px",
                    background: "#edf2f4",
                    display: "flex",
                    borderRadius: "27.5px",
                    position: "relative",
                    zIndex: 9,
                    alignItems: "center",
                  }}
                  className={`border ${isModalOpen && "focus-border"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000"
                    height={"24px"}
                    width={"24px"}
                    fill="transparent"
                    className="ml-10 mr-10"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                  <input
                    type="search"
                    placeholder="Search Products"
                    value={searchQuery}
                    onChange={(event) => {
                      setSearchQuery(event.target.value);
                    }}
                    className="search-input"
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn-close"
            style={{
              top: "14px",
              height: "38px",
              width: "40px",
              borderRadius: "100px",
            }}
            onClick={closeModal}
          >
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="p-20">
            {searchLoading ? (
              <>
                <div className="grid-1 w-full">
                  {new Array(searchFilter?.limit || 8).fill(1)?.map((el) => {
                    return <HorizontalLoadingCard />;
                  })}
                </div>
                <div className="flex-justify-center-items-center pt-40 pb-40"></div>
              </>
            ) : searchedProducts.length === 0 && searchError ? (
              <div className="h-200">
                <h3>Oops! No Data Found!</h3>
              </div>
            ) : searchedProducts.length > 0 ? (
              <div className="product-wrap">
                <div className="grid-1">
                  {searchedProducts.map((product, index) => (
                    <HorizontalProductCard
                      onTitleClick={() => closeModal()}
                      product={product}
                    />
                  ))}
                </div>
                <div className="flex-justify-center-items-center pt-20 pb-20">
                  <Pagination
                    count={Math.ceil(
                      searchFilter.totalRecords / searchFilter.limit
                    )}
                    size={"large"}
                    page={searchFilter?.page}
                    defaultPage={searchFilter?.page}
                    onChange={handleSearchPageChange}
                    siblingCount={isMobile ? 0 : 1}
                    boundaryCount={isMobile ? 0 : 1}
                  />
                </div>
              </div>
            ) : (
              <></>
              // <div className="w-full h-200 text-center">
              //   <h3>Start Searching products</h3>
              // </div>
            )}
          </div>

          {/* <div className="p-20 flex justify-center items-center">
            {searchLoading ? (
              <>
                <div className="grid-3 w-full">
                  {new Array(searchFilter?.limit || 8).fill(1)?.map((el) => {
                    return <LoadingCard />;
                  })}
                </div>
                <div className="flex-justify-center-items-center pt-40 pb-40"></div>
              </>
            ) : searchedProducts.length === 0 && searchError ? (
              <div className="h-200 flex justify-center items-center">
                <h3>Oops! No Data Found!</h3>
              </div>
            ) : searchedProducts.length > 0 ? (
              <div className="product-wrap">
                <div className="grid-3">
                  {searchedProducts.map((product, index) => (
                    <ProductCard
                      product={product}
                      key={index}
                      addToWishListProduct={addToWishListProduct}
                      addToCartProduct={addToCartProduct}
                      carts={props.carts}
                      handleClickOpen={handleClickOpen}
                      className="col-xl-12 col-lg-12 col-sm-12 col-12"
                      {...props}
                    />
                  ))}
                </div>
                <div className="flex-justify-center-items-center pt-20 pb-20">
                  <Pagination
                    count={Math.ceil(
                      searchFilter.totalRecords / searchFilter.limit
                    )}
                    size={"large"}
                    page={searchFilter?.page}
                    defaultPage={searchFilter?.page}
                    onChange={handleSearchPageChange}
                    siblingCount={0}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-200 text-center flex justify-center items-center">
                <h3>Start Searching products</h3>
              </div>
            )}
          </div>
          <div className="p-10">
            <h2>Recomended</h2>
            <Recomended
              addToCartProduct={addToCartProduct}
              addToWishListProduct={addToWishListProduct}
              {...props}
            />
          </div> */}
        </DialogContent>
      </Dialog>
      {/* <ViewModal
        addToCartProduct={addToCartProduct}
        open={open}
        onClose={handleClose}
        product={state}
        carts={props.carts}
        {...props}
      /> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps, {
  addToCart,
  addToWishList,
  addToCompareList,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
})(SearchModal);
