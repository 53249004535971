import React, { Fragment, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./style.css";
import { Drawer } from "@mui/material";
import LoginIcon from "../../icons/LoginIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { checkLoggedIn } from "../../utils";
import UserIcon from "../../icons/UserIcon";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.07926 0.222253C7.31275 -0.007434 7.6873 -0.007434 7.92079 0.222253L14.6708 6.86227C14.907 7.09465 14.9101 7.47453 14.6778 7.71076C14.4454 7.947 14.0655 7.95012 13.8293 7.71773L13 6.90201V12.5C13 12.7761 12.7762 13 12.5 13H2.50002C2.22388 13 2.00002 12.7761 2.00002 12.5V6.90201L1.17079 7.71773C0.934558 7.95012 0.554672 7.947 0.32229 7.71076C0.0899079 7.47453 0.0930283 7.09465 0.32926 6.86227L7.07926 0.222253ZM7.50002 1.49163L12 5.91831V12H10V8.49999C10 8.22385 9.77617 7.99999 9.50002 7.99999H6.50002C6.22388 7.99999 6.00002 8.22385 6.00002 8.49999V12H3.00002V5.91831L7.50002 1.49163ZM7.00002 12H9.00002V8.99999H7.00002V12Z"
          fill="currentColor"
          fill-rule="evenodd"
          clip-rule="evenodd"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Shop",
    link: "/shop",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
        />
      </svg>
    ),
  },
  {
    id: 3,
    title: "Your Orders",
    link: "/your-orders",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        width="18"
        height="18"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    ),
  },
];

const MobileMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth, token, openLogoutModal, configs } = props;
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setMenuState(false);
  }, [checkLoggedIn()]);

  return (
    <>
      <div
        className="showmenu mobail-menu m-10"
        onClick={() => setMenuState(!menuActive)}
      >
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>

      {/* <button onClick={() => setMenuState(!menuActive)}>Open drawer</button> */}
      <Drawer
        open={menuActive}
        onClose={() => setMenuState(!menuActive)}
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: "9999",
            width: "300px",
            background: "#102d62",
          },
        }}
      >
        <div className="menu-close m-10">
          <div className="close" onClick={() => setMenuState(!menuActive)}>
            <i className="icon-icon_close"></i>
          </div>
        </div>
        <ul className="responsivemenu p-10">
          {menus?.map((item, mn) => {
            return (
              <button
                onClick={() => navigate(item?.link)}
                className={` ${
                  item.link === location?.pathname
                    ? "mobile-nav-link-btn-active"
                    : "mobile-nav-link-btn"
                }`}
                key={mn}
              >
                {item?.icon}
                {item?.title}
              </button>
            );
          })}
          {isAuth && token ? (
            <div className="flex items-center gap-2">
              <button className="logout-button" onClick={openLogoutModal}>
                <LogoutIcon />
                <span className="ml-4 flex flex-col">
                  <small
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100px",
                      lineHeight: "14px",
                    }}
                  >
                    {configs?.fullname}
                  </small>

                  <span>log-out </span>
                </span>
              </button>
              <div className="profile">
                <Link to="/user-profile">
                  <button className="profile-button">
                    <UserIcon />
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <button
              className="logout-button"
              onClick={() => ClickHandler("/login")}
            >
              <LoginIcon />
              <span className="flex flex-col">
                <small
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100px",
                    textAlign: "left",
                  }}
                >
                  My Account
                </small>
                <small
                  style={{ textAlign: "left" }}
                  className="inline-flex text-left"
                >
                  Hello, Sign in
                </small>
              </span>
            </button>
          )}
        </ul>
      </Drawer>
    </>

    // <div>
    //   <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
    //     <div className="menu-close m-10">
    //       <div className="clox" onClick={() => setMenuState(!menuActive)}>
    //         <i className="icon-icon_close"></i>
    //       </div>
    //     </div>

    //     <ul className="responsivemenu p-10">
    //       {menus.map((item, mn) => {
    //         return (
    //           <button
    //             onClick={() => navigate(item?.link)}
    //             className={` ${
    //               item.link === location?.pathname
    //                 ? "mobile-nav-link-btn-active"
    //                 : "mobile-nav-link-btn"
    //             }`}
    //             key={mn}
    //           >
    //             {item?.icon}
    //             {item?.title}
    //           </button>
    //         );
    //       })}
    //       {isAuth && token ? (
    //         <button
    //           className="btn btn-secondary flex items-center w-full mt-3"
    //           onClick={openLogoutModal}
    //         >
    //           <i className="icon-user-1 w-1/4"></i>
    //           <span>
    //             <small
    //               style={{
    //                 whiteSpace: "nowrap",
    //                 overflow: "hidden",
    //                 textOverflow: "ellipsis",
    //                 width: "100px",
    //                 textAlign: "left",
    //               }}
    //             >
    //               {configs?.fullname}
    //             </small>
    //             <small
    //               style={{ textAlign: "left" }}
    //               className="inline-flex text-left"
    //             >
    //               log-out{" "}
    //             </small>
    //           </span>
    //         </button>
    //       ) : (
    //         <button
    //           className="btn btn-secondary flex items-center w-full mt-3"
    //           onClick={() => ClickHandler("/login")}
    //         >
    //           <i className="icon-user-1 w-1/4"></i>
    //           <span className="flex flex-col">
    //             <small
    //               style={{
    //                 whiteSpace: "nowrap",
    //                 overflow: "hidden",
    //                 textOverflow: "ellipsis",
    //                 width: "100px",
    //                 textAlign: "left",
    //               }}
    //             >
    //               My Account
    //             </small>
    //             <small
    //               style={{ textAlign: "left" }}
    //               className="inline-flex text-left"
    //             >
    //               Hello, Sign in
    //             </small>
    //           </span>
    //         </button>
    //       )}
    //       {/* {menus.map((item, mn) => {
    //         return (
    //           <ListItem
    //             className={
    //               item.link === location?.pathname
    //                 ? "selected-route bg-sec"
    //                 : null
    //             }
    //             key={mn}
    //           >
    //             {item.submenu ? (
    //               <Fragment>
    //                 <p
    //                 //   onClick={() =>
    //                 //     setOpenId(item.id === openId ? 0 : item.id)
    //                 //   }
    //                 >
    //                   {item.title}
    //                   <i
    //                     className={
    //                       item.id === openId ? "ti-angle-up" : "ti-angle-down"
    //                     }
    //                   ></i>
    //                 </p>
    //                 <Collapse
    //                   in={item.id === openId}
    //                   timeout="auto"
    //                   unmountOnExit
    //                 >
    //                   <List className="subMenu">
    //                     <Fragment>
    //                       {item.submenu.map((submenu, i) => {
    //                         return (
    //                           <ListItem key={i}>
    //                             <Link
    //                               onClick={ClickHandler}
    //                               className="active"
    //                               to={submenu.link}
    //                             >
    //                               {submenu.title}
    //                             </Link>
    //                           </ListItem>
    //                         );
    //                       })}
    //                     </Fragment>
    //                   </List>
    //                 </Collapse>
    //               </Fragment>
    //             ) : (
    //               <Link
    //                 style={{ width: "100%" }}
    //                 className="active"
    //                 to={item.link}
    //               >
    //                 {item.title}
    //               </Link>
    //             )}
    //           </ListItem>
    //         );
    //       })} */}
    //     </ul>
    //   </div>

    //   <div
    //     className="showmenu mobail-menu m-10"
    //     onClick={() => setMenuState(!menuActive)}
    //   >
    //     <button type="button" className="navbar-toggler open-btn">
    //       <span className="icon-bar first-angle"></span>
    //       <span className="icon-bar middle-angle"></span>
    //       <span className="icon-bar last-angle"></span>
    //     </button>
    //   </div>
    // </div>
  );
};

export default MobileMenu;
