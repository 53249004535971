import React from "react";
import UserDetails from "./UserDetails";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";

const UserProfilePage = (props) => {
  return (
    <>
      <Navbar />
      <UserDetails {...props} />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(UserProfilePage);
