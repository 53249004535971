import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { ShimmerCategoryList, ShimmerTitle } from "react-shimmer-effects";
import _, { isBoolean, isEmpty, isNumber, isObject, isString } from "lodash";
import Categorys from "../../components/Categorys/Categorys";
import Recomended from "../../components/Recomended/Recomended";
import Footer from "../../components/footer/Footer";
import OrderCard from "../../components/Cards/OrderCard";
import { Dialog, DialogTitle, Pagination } from "@mui/material";
import ReactSelect from "../../components/UI/Select/ReactSelect";
import { RemoveEmptyValues } from "../../utils";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { order_status_array, payment_status_array } from "../../utils/data";
import CustomStepper from "../../components/UI/Stepper/CustomStepper";

const dataObj = {
  _id: { label: "Order Id" },
  userid: { label: "User Id" },
  orderItems: { label: "Ordered Items" },
  shippingAddress: { label: "Shipping Address" },
  orderStatus: { label: "Order Status" },
  totalPrice: { label: "Total Price" },
  isPaid: { label: "Paid" },
  paidAt: { label: "Paid At" },
  transactionId: { label: "Transaction Id" },
  method: { label: "Method" },
  message: { label: "Message" },
  paymentStatus: { label: "Payment Status" },
  deliveredAt: { label: "Delivered At" },
  delivery_charge: { label: "Delivery Charges" },
  createdAt: { label: "Created At" },
  updatedAt: { label: "Updated At" },
};

const dateConfig = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true, // This makes it 12-hour format (AM/PM)
};

const getStepperValue = (val) => {
  switch (val) {
    case "Pending":
      return 1;

    case "Processing":
      return 2;

    case "Dispatched":
      return 3;

    case "Delivered":
      return 4;

    case "Cancelled":
      return 4;

    default:
      return 0;
  }
};
const getStepperColor = (val) => {
  switch (val) {
    case "Pending":
      return "#ffab00";

    case "Processing":
      return "#3b5998";

    case "Dispatched":
      return "#0090e7";

    case "Delivered":
      return "#00d25b";

    default:
      return "#00d25b";
  }
};

const YourOrdersPage = (props) => {
  const { token } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    page: searchParams.get("page") || 1,
    limit: searchParams.get("limit") || 10,
    orderStatus: searchParams.get("orderStatus") || "Processing",
    paymentStatus: searchParams.get("paymentStatus") || "",
    totalRecords: null,
  });

  const [yourOrders, setYourOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [state, setState] = useState({});
  const [open, setOpen] = React.useState(false);

  function handleClose() {
    setOpen(false);
    setState({});
  }
  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };
  const handlePageChange = (event, value) => {
    setFilters((prev) => ({ ...prev, page: value }));
  };

  const handleFilterChange = (name, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(name, value);
      setSearchParams(newSearchParams);
    } else {
      newSearchParams.delete(name);
      setSearchParams(newSearchParams);
    }
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getUserOrdersData = async () => {
    setLoading(true);
    if (error) setError(false);
    await axios
      .get("orders", {
        params: { ...RemoveEmptyValues(filters) },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setYourOrders(res?.data?.data);
        setFilters((prev) => ({
          ...prev,
          totalRecords: res?.data?.totalRecords,
        }));
        setLoading(false);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message);
        setYourOrders(null);
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getUserOrdersData();
  }, [
    filters?.page,
    filters?.limit,
    filters?.orderStatus,
    filters?.paymentStatus,
  ]);

  return (
    <Fragment>
      <div className="my-orders-wrap">
        <Navbar />
        <div className="row sm-container sm-separator-padding mb-4 mt-4">
          <div className="col-6 col-lg-4">
            <h6>Order Status</h6>
            <ReactSelect
              isClearable
              loading={loading}
              defaultValue={{
                value: filters.orderStatus,
                label: filters.orderStatus,
              }}
              options={order_status_array
                ?.filter((elm) => elm !== "Pending")
                ?.map((el) => ({
                  value: el,
                  label: el,
                }))}
              onChange={(selected) => {
                handleFilterChange("orderStatus", selected?.value);
              }}
              value={{
                value: filters.orderStatus,
                label: filters.orderStatus,
              }}
            />
          </div>
          <div className="col-6 col-lg-4">
            <h6>Payment Status</h6>
            <ReactSelect
              isClearable
              loading={loading}
              defaultValue={{
                value: filters.paymentStatus,
                label: filters.paymentStatus,
              }}
              options={payment_status_array
                ?.filter((elm) => elm !== "Pending")
                .map((el) => ({
                  value: el,
                  label: el,
                }))}
              onChange={(selected) => {
                handleFilterChange("paymentStatus", selected?.value);
              }}
              value={{
                value: filters.paymentStatus,
                label: filters.paymentStatus,
              }}
            />
          </div>
        </div>
        <div className=" sm-container sm-separator-padding">
          {loading ? (
            <div className="form">
              <div className="cart-wrapper">
                <h2 className="mb-4">Your Orders</h2>
                <div className="grid-2">
                  {new Array(filters?.limit || 10).fill(1)?.map((el) => {
                    return (
                      <div
                        className="h-350 border w-full p-30"
                        style={{
                          borderRadius: "20px",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      >
                        <ShimmerTitle
                          line={2}
                          clasName={"mb-0"}
                          gap={10}
                          variant={"secondary"}
                        />
                        <ShimmerTitle
                          line={3}
                          clasName={"mb-0"}
                          gap={10}
                          variant={"secondary"}
                        />
                        <ShimmerTitle
                          line={2}
                          clasName={"mb-0"}
                          gap={10}
                          variant={"secondary"}
                        />
                        <ShimmerTitle
                          line={3}
                          clasName={"mb-0"}
                          gap={10}
                          variant={"secondary"}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="flex-justify-center-items-center h-45"></div>
              </div>
            </div>
          ) : _?.isEmpty(yourOrders) ? (
            // Empty orders state
            <div className="form">
              <div
                className="cart-wrapper flex justify-center items-center border mb-40 row"
                style={{ borderRadius: "20px" }}
              >
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                  {/* <img src={emptyCart} alt="cart-images" /> */}
                </div>
                <div className="col-12">
                  <div className="heading-title flex flex-col justify-center items-center m-0 p-20">
                    <h2>No Data Found!</h2>
                    <p>Start Ordering Items.</p>
                    <Link className="btn-style-1" to={"/shop"}>
                      Shop
                    </Link>
                  </div>
                  <div className="p-10">
                    <div className="heading-title">
                      <h2 className="mb-10">Featured Category</h2>
                    </div>
                    <Categorys
                      showTitle={false}
                      className={"p-0 m-0 pl-0"}
                      innerClassName={"p-0 m-0 pl-0"}
                      {...props}
                    />
                  </div>
                  <div className="p-10 pb-20">
                    <div className="heading-title mt-20 ">
                      <h2>Recommended</h2>
                    </div>
                    <Recomended />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // Orders items display
            <div className="form">
              <div className="cart-wrapper">
                <h2 className="mb-4">Your Orders</h2>

                <div className="grid-2">
                  {yourOrders?.map((order) => {
                    return (
                      <OrderCard
                        order={order}
                        handleOpen={handleClickOpen}
                        getStepperValue={getStepperValue}
                        getStepperColor={getStepperColor}
                      />
                    );
                  })}
                </div>
                <div className="flex-justify-center-items-center pt-20 pb-20">
                  <Pagination
                    count={Math.ceil(filters?.totalRecords / filters?.limit)}
                    size={"large"}
                    page={filters?.page}
                    defaultPage={filters?.page}
                    onChange={handlePageChange}
                    siblingCount={0}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      <Dialog
        fullWidth={true}
        open={open}
        className="modalWrapper cart-quickview"
        fullScreen
        maxWidth={"xl"}
      >
        <DialogTitle
          sx={{
            position: "sticky",
            top: "0px",
            zIndex: 9999,
            backgroundColor: "#fafafa",
          }}
        >
          Order Details
          <button
            onClick={handleClose}
            className="btn btn-close"
            disabled={loading}
          >
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <div
          className="your-orders-card p-20"
          key={state?._id} // Use index as key
        >
          {Object?.keys(state)
            ?.filter((address) => !["userid"].includes(address)) // excluded keys array
            .map((key) => {
              const value = state?.[key] || {};
              if (isObject(value) && value && Object.keys(value).length > 0) {
                if (key === "orderItems" && state?.orderItems) {
                  return (
                    <div>
                      <p>Ordered Items</p>
                      <div className="grid-3 mb-4 mt-4">
                        {state?.orderItems?.map((orderItemKey) => {
                          return (
                            <div className="product-item border p-10 rounded-xl shadow-md">
                              <div className="product-image">
                                <img
                                  src={orderItemKey?.proimg?.[0]?.src}
                                  alt={orderItemKey?.proimg?.[0]?.id}
                                />
                              </div>
                              <div className="product-info">
                                <div className="product-card-title">
                                  <h2
                                    data-tooltip-id="title-tooltip"
                                    data-tooltip-content={orderItemKey?.title}
                                    data-tooltip-place="top"
                                  >
                                    <h4 style={{ wordBreak: "break-all" }}>
                                      {orderItemKey.name}
                                    </h4>
                                  </h2>
                                </div>

                                <div className="w-full">
                                  <h6>Category : {orderItemKey?.category}</h6>
                                </div>
                                <div className="w-full">
                                  <h6>Brand : {orderItemKey?.brand}</h6>
                                </div>

                                <div className="product-card-footer">
                                  <div className="w-full">
                                    <span className="present-price">
                                      ${orderItemKey?.price}
                                    </span>
                                    {/* <del className="old-price">
                                  ${orderItemKey?.delPrice}
                                </del> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else if (key === "shippingAddress") {
                  let excludeKeys = ["addresid"];
                  return (
                    state?.shippingAddress && (
                      <div className="mb-3">
                        <p>Shipping Address</p>
                        <TableContainer className="rounded-xl shadow-md">
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow
                                sx={{
                                  backgroundColor: "#d3d3d3",
                                }}
                              >
                                {Object.keys(state?.shippingAddress)
                                  .filter(
                                    (address) => !excludeKeys.includes(address)
                                  )
                                  .map((address) => {
                                    return (
                                      <TableCell
                                        variant="head"
                                        sx={{
                                          minWidth:
                                            address === "street" ||
                                            address === "addressLine2"
                                              ? 200
                                              : 120,
                                        }}
                                      >
                                        {address}
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(state?.shippingAddress)
                                .filter(
                                  (address) => !excludeKeys.includes(address)
                                )
                                .map((address) => {
                                  return (
                                    <TableCell>
                                      {state?.shippingAddress?.[address]}
                                    </TableCell>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )
                  ); // Return an empty fragment if it's shippingAddress
                }
              }

              if (isString(value) || isNumber(value) || isBoolean(value)) {
                let content;

                switch (key) {
                  case "paymentStatus":
                    content = (
                      <span className={value?.toLowerCase()}>{value}</span>
                    );
                    break;

                  case "orderStatus":
                    content =
                      value === "Cancelled" ? (
                        <>
                          <span className={`${value?.toLowerCase()}`}>
                            {value}
                          </span>
                          <div className="py-4">
                            <CustomStepper
                              data={order_status_array?.filter(
                                (label) =>
                                  label !== "Delivered" &&
                                  label !== "Dispatched"
                              )}
                              activeStep={
                                order_status_array?.filter(
                                  (label) =>
                                    label !== "Delivered" &&
                                    label !== "Dispatched"
                                )?.length
                              }
                              className={"mt-4"}
                              color={"red"}
                              activeBorderColor={"red"}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <span className={value?.toLowerCase()}>{value}</span>
                          <div className="py-4">
                            <CustomStepper
                              data={order_status_array?.filter(
                                (label) => label !== "Cancelled"
                              )}
                              activeStep={getStepperValue(value)}
                              className={"mt-4"}
                              color={getStepperColor(value)}
                              activeBorderColor={getStepperColor(value)}
                            />
                          </div>
                        </>
                      );
                    break;

                  case "_id":
                    content = (
                      <span className={"color-custom-purple"}>{value}</span>
                    );
                    break;

                  case "createdAt":
                    content = new Date(value).toLocaleString(
                      "en-US",
                      dateConfig
                    );
                    break;

                  case "updatedAt":
                    content = new Date(value).toLocaleString(
                      "en-US",
                      dateConfig
                    );
                    break;

                  case "totalPrice":
                    content = (
                      <span className="color-custom-green">{`$${value.toFixed(
                        2
                      )}`}</span>
                    ); // Assuming it's a number, formatting it as currency
                    break;

                  case "delivery_charge":
                    content = `$${value}`; // Assuming delivery_charge
                    break;

                  default:
                    content = value;
                    break;
                }

                return (
                  <p key={key}>
                    {dataObj?.[key]?.label} : {content}
                  </p>
                );
              }

              return null; // Return null if none of the conditions match
            })}
          <div></div>
        </div>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    configs: state.configs,
    token: state.configs?.token,
  };
};

export default connect(mapStateToProps)(YourOrdersPage);
