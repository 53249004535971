import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { mergeClasses } from "../../utils";

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 5,
  slidesToScroll: 5,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 376,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 175,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: false,
      },
    },
  ],
};

const Categorys = ({
  showTitle = true,
  className,
  innerClassName,
  ...props
}) => {
  const navigate = useNavigate();
 

  const handleCategoryClick = (category) => {
    // Create URLSearchParams object
    const searchParams = new URLSearchParams();
    searchParams.set("category", category);

    // Navigate to the new route with the updated query params
    navigate(`/shop?${searchParams.toString()}`);
  };

  return (
    <section
      className={mergeClasses("featured-section sm-separator-padding pb-0", className)}
    >
      <div className={mergeClasses("sm-container", innerClassName)}>
        {showTitle && (
          <div className="row">
            <div className="col-lg-6">
              <SectionTitle sTitle={"Featured categorys"} />
            </div>
          </div>
        )}
        <div className="featured-category-slider featured-category-slider-active">
          <Slider {...settings}>
            {props?.configs?.categories?.map((category, cat) => (
              <div className="featured-item" key={cat}>
                <div className="icon">
                  <img
                    src={category?.url}
                    className="icon-image"
                    alt="jh"
                  />
                </div>
                <div className="text">
                  <h2>
                    <button
                      className="link-button"
                      onClick={() => handleCategoryClick(category?.name)} // Avoid default button behavior
                    >
                      {category?.name}
                    </button>
                  </h2>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Categorys;
