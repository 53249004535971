import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
import PageTitle from "../../components/pagetitle/PageTitle";
import About from "../../components/about/about";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import AboutHistory from "../../components/AboutHistory/AboutHistory";
import CtaBannerS5 from "../../components/CtaBannerS5/CtaBannerS5";

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={"About Us"} pagesub={"About"} />
      <div className="about-page">
        <About />
        <AboutHistory />
      </div>
      <CtaBannerS5 />

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
