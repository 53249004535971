import React, { Fragment, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import noAddresses from "../../images/no-addresses-found.png";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomPhoneInput from "../Input/CustomPhoneInput";
import axios from "axios";
import { useEffect } from "react";
import { PulseLoader } from "react-spinners";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import { updateConfigs } from "../../store/actions/action";
import { connect } from "react-redux";
import { mergeClasses } from "../../utils";
import AddressCard from "../Cards/AddressCard";
import ReactSelect from "../UI/Select/ReactSelect";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import { ShimmerTitle } from "react-shimmer-effects";
import { isEmpty } from "lodash";
const AddressesSection = ({
  cartList,
  configs,
  token,
  updateConfigs,
  ...props
}) => {
  const { ip_data } = configs;
  const [value, setValue] = useState({
    fullname: configs?.fullname || "",
    phoneNumber: configs?.mobile_no || "",
    countrycode:
      configs?.country_code === "971"
        ? "+1"
        : configs?.country_code
        ? `+${configs?.country_code}`
        : "+1",
    street: "",
    address2: "",
    type: "",
    landmark: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    otp: "",
    otpSent: false,
    otpVerified: false,
  });

  const [editValue, setEditValue] = useState({
    fullname: "",
    phoneNumber: "",
    countrycode: "",
    street: "",
    address2: "",
    type: "",
    landmark: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    isAlternativeAddress: false,
    otp: "",
    otpSent: false,
    otpVerified: false,
  });

  const checkCountry = (phoneNumber) => {
    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);
      if (parsedNumber) {
        return parsedNumber.country; // Returns the country code, e.g., "US", "IN"
      }
    } catch (error) {
      return "US";
    }
  };

  const valueRef = useRef();
  const editRef = useRef();

  // useEffect(() => {
  //   valueRef.current = {
  //     COUNTRY_CODE:
  //       ip_data?.country_code === "ae"
  //         ? "US"
  //         : configs?.ip_data?.country_code?.toUpperCase() || "US",
  //   }; // adding the default country_code in the ref so that the phone input will check the valid number
  //   setValue((prev) => ({
  //     ...prev,
  //     COUNTRY_CODE:
  //       ip_data?.country_code === "ae"
  //         ? "US"
  //         : configs?.ip_data?.country_code?.toUpperCase() || "US",
  //   }));
  // }, []);

  // adding the default user data

  useEffect(() => {
    if (configs?.country_code && configs?.mobile_no) {
      const country = checkCountry(
        `+${configs?.country_code}${configs?.mobile_no}`
      );
      valueRef.current = {
        COUNTRY_CODE: country,
      };
      setValue((prev) => ({
        ...prev,
        COUNTRY_CODE: country,
      }));
    }
    setValue((prev) => ({
      ...prev,
      phoneNumber: configs?.mobile_no || "",
      countrycode:
        configs?.country_code === "971"
          ? "+1"
          : configs?.country_code
          ? `+${configs?.country_code}`
          : "+1",
    }));
  }, [configs?.country_code, configs?.mobile_no]);

  useEffect(() => {
    editRef.current = {
      COUNTRY_CODE: editValue?.COUNTRY_CODE,
      // ip_data?.country_code === "ae"
      //   ? "US"
      //   : editValue?.COUNTRY_CODE ||
      //     configs?.ip_data?.country_code?.toUpperCase() ||
      //     "US",
    }; // adding the default country_code in the ref so that the phone input will check the valid number
    setEditValue((prev) => ({
      ...prev,
      COUNTRY_CODE: editValue?.COUNTRY_CODE,
      // ip_data?.country_code === "ae"
      //   ? "US"
      //   : editValue?.COUNTRY_CODE ||
      //     configs?.ip_data?.country_code?.toUpperCase() ||
      //     "US",
    }));
  }, [editValue?.phoneNumber]);

  const [selectedAddress, setSelectedAddress] = useState(
    configs?.selectedAddress || null
  );

  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const [editAddress, setEditAddress] = useState(false);
  const [editAddressLoading, setEditAddressLoading] = useState(false);
  const [editAddressError, setEditAddressError] = useState(false);

  const [deleteAddress, setDeleteAddress] = useState(false);
  const [deleteAddressLoading, setDeleteAddressLoading] = useState(false);
  const [deleteAddressError, setDeleteAddressError] = useState(false);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const onClose = () => {
    setShowNewAddressForm(false);
  };

  const changeHandler = (e) => {
    setValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const editChangeHandler = (e) => {
    setEditValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const selectSavedAddress = (addressId) => {
    const address = configs?.savedAddresses.find(
      (addr) => addr._id === addressId
    );
    setSelectedAddress(address);
    updateConfigs({ selectedAddress: address });
  };

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
      validators: {
        phoneNumber: {
          message: "The :attribute must be a valid phone number.",
          rule: (val, params, validator) => {
            return isValidNumber(
              `${val.toString()}`,
              valueRef?.current?.COUNTRY_CODE
            );
          },
          required: true,
        },
      },
    })
  );

  const [editValidator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
      validators: {
        phoneNumber: {
          message: "The :attribute must be a valid phone number.",
          rule: (val, params, validator) => {
            return isValidNumber(
              `${val.toString()}`,
              editRef?.current?.COUNTRY_CODE
            );
          },
          required: true,
        },
      },
    })
  );

  const handlePhoneChange = (phoneValue, data) => {
    // Extract the phone number without the dial code
    const dialCode = data.dialCode;
    const phoneWithoutDialCode = phoneValue.slice(data.dialCode.length);

    // Update the state with the new values
    setValue((prev) => ({
      ...prev,
      phoneNumber: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    }));
    valueRef.current = {
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    };
    validator.showMessages();
  };

  const handleEditPhoneChange = (phoneValue, data) => {
    // Extract the phone number without the dial code
    const dialCode = data.dialCode;
    const phoneWithoutDialCode = phoneValue.slice(data.dialCode.length);

    // Update the state with the new values
    setEditValue((prev) => ({
      ...prev,
      phoneNumber: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    }));
    editRef.current = {
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    };
    editValidator.showMessages();
  };

  const addNewAddress = async () => {
    if (validator.allValid()) {
      validator.hideMessages();
      setAddressLoading(true);
      if (addressError) setAddressError(false);
      // if (
      //   value?.isAlternativeAddress &&
      //   value?.phoneNumber !== configs?.mobile_no
      // ) {
      //   setAddressLoading(true);

      //   try {
      //     if (!value?.otpSent && !value?.otpVerified) {
      //       // Send OTP
      //       const response = await axios.post("add-address", {
      //         headers: { Authorization: `Bearer ${token}` },
      //       });
      //       toast.success(response?.data?.message || "OTP sent successfully");
      //       return; // RETURN THE FUNCTION
      //     } else if (value?.otpSent && !value?.otpVerified) {
      //       // Verify OTP
      //       const response = await axios.post("add-address", {
      //         headers: { Authorization: `Bearer ${token}` },
      //       });
      //       toast.success(
      //         response?.data?.message || "OTP verified successfully"
      //       );
      //       return; // RETURN THE FUNCTION
      //     }
      //   } catch (error) {
      //     toast.error(error?.response?.data?.message || "An error occurred");
      //   } finally {
      //     setAddressLoading(false);
      //   }
      // }

      const newAddress = {
        ...value,
        _id: configs.userid,
      };
      await axios
        .post("add-address", newAddress, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (res) => {
          await getNewAddress();
          setAddressLoading(false);
          setShowNewAddressForm(false);
          toast.success(res?.data?.message);
          setValue((prev) => ({
            ...prev,
            street: "",
            address2: "",
            type: "",
            landmark: "",
            country: "",
            state: "",
            city: "",
            postalCode: "",
            otp: "",
            otpSent: false,
            otpVerified: false,
          }));
        })
        .catch((err) => {
          setAddressLoading(false);
          setAddressError(true);
        });
    } else {
      validator.showMessages();
      let errors = Object.keys(validator.errorMessages);
      for (let i = 0; i < errors.length; i++) {
        if (validator.errorMessages[errors[i]]) {
          toast.error(validator.errorMessages[errors[i]]);
          return;
        }
      }
    }
  };

  const getNewAddress = async () => {
    setAddressLoading(true);
    if (addressError) setAddressError(false);

    await axios
      .get("get-address", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAddressLoading(false);
        let preferred = res?.data?.data?.filter(
          (address) => address?.preferred === "true"
        );
        if (isEmpty(preferred)) {
          updateConfigs({
            savedAddresses: [...res?.data?.data],
            selectedAddress: {},
          });
          setSelectedAddress({});
        } else {
          updateConfigs({
            savedAddresses: [...res?.data?.data],
            selectedAddress: preferred?.[0],
          });
          setSelectedAddress(preferred?.[0]);
        }
      })
      .catch((err) => {
        updateConfigs({
          savedAddresses: [],
          selectedAddress: {},
        });
        setSelectedAddress({});
        setAddressLoading(false);
        setAddressError(true);
      });
  };

  const onDeleteAddress = async () => {
    setDeleteAddressLoading(true);
    if (deleteAddressError) setDeleteAddressError(false);

    await axios
      .delete("delete-address", {
        data: { id: selectedAddress?._id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        await getNewAddress();
        // let filteredData = configs?.savedAddresses.filter(
        //   (el) => el?._id !== selectedAddress?._id
        // );
        // updateConfigs({
        //   savedAddresses: [...filteredData],
        //   selectedAddress: {},
        // });
        setDeleteAddressLoading(false);
        setDeleteAddress(false);
        toast.error(res?.data?.message);
      })
      .catch((err) => {
        toast.success(err?.response?.data.message);
        setDeleteAddressLoading(false);
        setDeleteAddressError(true);
      });
  };

  const onEditAddress = async () => {
    if (editValidator?.allValid()) {
      setEditAddressLoading(true);
      if (editAddressError) setEditAddressError(false);

      await axios
        .patch(
          "update-address",
          {
            ...editValue,
            preferred: true,
            addresid: editValue?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          await getNewAddress();
          // let filteredData = configs?.savedAddresses.map((el) =>
          //   el?._id === selectedAddress?._id ? { ...res.data.data } : el
          // );
          // updateConfigs({ savedAddresses: [...filteredData] });
          setEditAddressLoading(false);
          setEditAddress(false);
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.success(err?.response?.data.message);
          setEditAddressLoading(false);
          setEditAddressError(true);
        });
    } else {
      editValidator.showMessages();
      setEditValue((prev) => ({ ...prev }));
      let errors = Object.keys(editValidator.errorMessages);
      for (let i = 0; i < errors.length; i++) {
        if (editValidator.errorMessages[errors[i]]) {
          toast.error(editValidator.errorMessages[errors[i]]);
          return;
        }
      }
    }
  };

  useEffect(() => {
    getNewAddress();
  }, []);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  return (
    <>
      <div className="biling-item">
        <div className="row heading-title flex justify-between items-start">
          <div className="col-lg-9 col-md-9 col-12">
            <h2>Billing Address</h2>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <button
              type="button"
              onClick={() => setShowNewAddressForm((prev) => !prev)}
              className="btn btn-primary"
            >
              Add New Address
            </button>
          </div>
        </div>
        <div className="billing-adress">
          <div className="contact-form form-style">
            <div className="row">
              {/* {selectedAddress && (
                <div className="col-12">
                  <h4>Selected Address</h4>
                  <p>
                    {selectedAddress.fname} {selectedAddress.lname},
                    {selectedAddress.email} ,{selectedAddress.phoneNumber},{" "}
                    {selectedAddress.street}, {selectedAddress.address2},{" "}
                    {selectedAddress.postalCode}
                  </p>
                </div>
              )} */}
              <div className="col-12">
                <h3>Saved Addresses</h3>
                {addressLoading ? (
                  <div>
                    {[0, 1, 2, 3]?.map((el) => {
                      return (
                        <div className="border mb-4 p-10 rounded-xl">
                          <ShimmerTitle
                            line={5}
                            gap={20}
                            className={"mb-0 w-full"}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <ul>
                    {configs?.savedAddresses?.length > 0 ? (
                      configs?.savedAddresses?.map((addr) => (
                        <li
                          onClick={() => {
                            selectSavedAddress(addr._id);
                          }}
                          key={addr._id}
                          className={mergeClasses(
                            "mb-4",
                            addr._id === selectedAddress?._id ||
                              addr?._id === configs?.selectedAddress?._id
                              ? "selected-address"
                              : "address"
                          )}
                        >
                          <AddressCard
                            address={addr}
                            selectSavedAddress={selectSavedAddress}
                            selectedAddress={selectedAddress}
                            onEditClick={(event) => {
                              setEditValue({
                                ...editValue,
                                ...selectedAddress,
                              });
                              setEditAddress(true);
                            }}
                            onDeleteClick={(event) => {
                              setDeleteAddress(true);
                            }}
                            configs={configs}
                            {...props}
                          />
                        </li>
                      ))
                    ) : (
                      <div className="mt-20 flex flex-col gap-2 items-center justify-center">
                        <h5>No Addresses Saved</h5>
                        <img
                          src={noAddresses}
                          alt=""
                          height={"50%"}
                          width={"50%"}
                        />
                      </div>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /ADD ADDRESS/ */}
      <Dialog
        open={showNewAddressForm}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          <h4>Add New Address</h4>
          <button className="btn-close" onClick={onClose}>
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="checkout-area">
            <div className="biling-item">
              <div className="billing-adress ">
                <div className="form-style row p-0 m-1">
                  <div className="col-lg-6 col-md-12 col-12">
                    <label>Full Name *</label>
                    <input
                      type="text"
                      placeholder="First name"
                      name="fullname"
                      value={value.fullname}
                      onChange={changeHandler}
                      disabled={addressLoading || !value?.isAlternativeAddress}
                    />
                    {validator.message("fullname", value.fullname, "required")}
                  </div>
                  {/* <div className="col-lg-6 col-md-12 col-12">
                    <label>Last name *</label>
                    <input
                      type="text"
                      placeholder="Last name"
                      name="lname"
                      value={value.lname}
                      onChange={changeHandler}
                    />
                    {validator.message("lname", value.lname, "required")}
                  </div> */}
                  {/* <div className="col-lg-6 col-md-12 col-12">
                    <label>Email *</label>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={value.email}
                      onChange={changeHandler}
                    />
                    {validator.message("email", value.email, "required|email")}
                  </div> */}
                  <div className="col-lg-6 col-md-12 col-12 relative">
                    <label>Phone *</label>
                    <CustomPhoneInput
                      country={value?.countrycode}
                      inputStyle={{
                        padding: "18.5px 14px 16px 58px",
                        height: "30px",
                      }}
                      onChange={handlePhoneChange}
                      value={`${value?.countrycode}${value.phoneNumber}`}
                      name="phoneNumber"
                      disabled={addressLoading || !value?.isAlternativeAddress}
                    />
                    {value?.otpSent && value?.otpVerified ? (
                      <svg
                        stroke={"green"}
                        fill={"green"}
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-14 right-12"
                      >
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                      </svg>
                    ) : null}
                    {validator.message(
                      "phoneNumber",
                      value.phoneNumber,
                      "required|phoneNumber"
                    )}
                  </div>
                  {/* OTP  */}
                  <div className="col-lg-6 col-md-6 col-12">
                    <label>
                      Is Alternative Address
                      <Checkbox
                        checked={value?.isAlternativeAddress}
                        onChange={(event) => {
                          setValue((prev) => ({
                            ...prev,
                            isAlternativeAddress: event?.target?.checked,
                          }));
                        }}
                        value={""}
                        name="isAlternativeAddress"
                      />
                    </label>
                  </div>
                  {value?.otpSent && (
                    <div className="col-lg-6 col-md-6 col-12">
                      <label>Enter Otp *</label>
                      <input
                        type="text"
                        placeholder="Enter Otp"
                        name="otp"
                        value={value?.otp}
                        onChange={changeHandler}
                        disabled={addressLoading}
                      />
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Street address *</label>
                    <input
                      type="text"
                      placeholder="Street address"
                      name="street"
                      value={value.street}
                      onChange={changeHandler}
                      disabled={addressLoading}
                    />
                    {validator.message("street", value.street, "required")}
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Street address line 2</label>
                    <input
                      type="text"
                      placeholder="Street address line 2"
                      name="address2"
                      value={value.address2}
                      onChange={changeHandler}
                      disabled={addressLoading}
                    />
                    {/* {validator.message("address2", value.address2, "")} */}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Landmark *</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={value.landmark}
                      onChange={changeHandler}
                      disabled={addressLoading}
                    />
                    {validator.message("landmark", value.landmark, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Type *</label>
                    <ReactSelect
                      options={[
                        {
                          value: "work",
                          label: "work",
                        },
                        {
                          value: "home",
                          label: "home",
                        },
                      ]}
                      onChange={(selected) => {
                        setValue((prev) => ({
                          ...prev,
                          type: selected?.value,
                        }));
                      }}
                      value={{
                        value: value.type,
                        label: value.type,
                      }}
                    />
                    {validator.message("type", value.type, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Country *</label>
                    <ReactSelect
                      loading={addressLoading}
                      options={countriesList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const country = selected; //here you will get full country object.
                        // if (isEmpty(country)) {
                        //   setValue((prev) => ({
                        //     ...prev,
                        //     country: "",
                        //     state: "",
                        //     city: "",
                        //   }));
                        //   return;
                        // }
                        setCountryid(country?.id);
                        GetState(country?.id).then((result) => {
                          setStateList(result);
                        });
                        setValue((prev) => ({
                          ...prev,
                          country: country?.name,
                          // state: "",
                          // city: "",
                        }));
                      }}
                      value={{
                        value: value.country,
                        label: value.country,
                      }}
                    />
                    {validator.message("country", value.country, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>State *</label>
                    <ReactSelect
                      loading={addressLoading}
                      options={stateList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const state = selected; //here you will get full country object.
                        // if (isEmpty(state)) {
                        //   setValue((prev) => ({
                        //     ...prev,
                        //     state: "",
                        //     city: "",
                        //   }));
                        //   return;
                        // }
                        setStateid(state.id);
                        GetCity(countryid, state.id).then((result) => {
                          setCityList(result);
                        });
                        setValue((prev) => ({
                          ...prev,
                          state: state.name,
                          city: "",
                        }));
                      }}
                      value={{
                        value: value.state,
                        label: value.state,
                      }}
                    />
                    {validator.message("state", value.state, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>City *</label>
                    <ReactSelect
                      loading={addressLoading}
                      options={cityList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const city = selected; //here you will get full country object.
                        if (isEmpty(city)) {
                          setValue((prev) => ({
                            ...prev,
                            city: "",
                          }));
                          return;
                        }
                        setCityid(city.id);
                        setValue((prev) => ({
                          ...prev,
                          city: city.name,
                        }));
                      }}
                      value={{
                        value: value.city,
                        label: value.city,
                      }}
                    />
                    {validator.message("city", value.city, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Zip / Postal code *</label>
                    <input
                      type="text"
                      placeholder="Zip/ Postal code *"
                      name="postalCode"
                      value={value.postalCode}
                      onChange={changeHandler}
                      disabled={addressLoading}
                    />
                    {validator.message(
                      "postalCode",
                      value.postalCode,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={addNewAddress}
              className="btn btn-primary p-10"
              disabled={addressLoading}
            >
              {addressLoading ? "Loading..." : "Save New Address"}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* /EDIT ADDRESS/ */}
      <Dialog
        open={editAddress}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          <h4>Edit Address</h4>

          <button
            className="btn-close"
            onClick={() => {
              setEditAddress(false);
            }}
            disabled={editAddressLoading}
          >
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="checkout-area">
            <div className="biling-item">
              <div className="billing-adress ">
                <div className="form-style row p-0 m-1">
                  <div className="col-lg-6 col-md-12 col-12">
                    <label>Full Name *</label>
                    <input
                      type="text"
                      placeholder="First name"
                      name="fullname"
                      value={editValue.fullname}
                      onChange={editChangeHandler}
                      disabled={
                        editAddressLoading || !editValue?.isAlternativeAddress
                      }
                    />
                    {editValidator.message(
                      "fullname",
                      editValue.fullname,
                      "required"
                    )}
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 relative">
                    <label>Phone *</label>

                    <CustomPhoneInput
                      country={editValue?.countrycode}
                      inputStyle={{
                        padding: "18.5px 14px 16px 58px",
                        height: "30px",
                      }}
                      onChange={handleEditPhoneChange}
                      value={`${editValue?.countrycode}${editValue.phoneNumber}`}
                      name="phoneNumber"
                      disabled={
                        editAddressLoading || !editValue?.isAlternativeAddress
                      }
                    />
                    {editValue?.otpSent && editValue?.otpVerified ? (
                      <svg
                        stroke={"green"}
                        fill={"green"}
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-14 right-12"
                      >
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                      </svg>
                    ) : null}

                    {editValidator.message(
                      "phoneNumber",
                      editValue.phoneNumber,
                      "required|phoneNumber"
                    )}
                  </div>

                  {/* OTP */}
                  <div className="col-lg-6 col-md-6 col-12 flex items-center justify-between gap-4">
                    <label className="flex items-center">
                      <Checkbox
                        checked={editValue?.isAlternativeAddress}
                        onChange={(event) => {
                          setEditValue((prev) => ({
                            ...prev,
                            isAlternativeAddress: event?.target?.checked,
                          }));
                        }}
                        value={""}
                        name="isAlternativeAddress"
                      />
                      Is Alternative Address
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 flex items-center justify-between gap-4">
                    <label className="flex items-center">
                      <Checkbox
                        checked={editValue?.preferred === "true"}
                        onChange={(event) => {
                          setEditValue((prev) => ({
                            ...prev,
                            preferred: event?.target?.checked?.toString(),
                          }));
                        }}
                        value={""}
                        name="preferred"
                      />
                      Preffered Address
                    </label>
                  </div>
                  {editValue?.otpSent && (
                    <div className="col-lg-6 col-md-6 col-12">
                      <label>Enter Otp *</label>
                      <input
                        type="text"
                        placeholder="Enter Otp"
                        name="otp"
                        value={editValue?.otp}
                        onChange={editChangeHandler}
                        disabled={editAddressLoading}
                      />
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Street address *</label>
                    <input
                      type="text"
                      placeholder="Street address"
                      name="street"
                      value={editValue.street}
                      onChange={editChangeHandler}
                      disabled={editAddressLoading}
                    />
                    {editValidator.message(
                      "street",
                      editValue.street,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Street address line 2</label>
                    <input
                      type="text"
                      placeholder="Street address line 2"
                      name="address2"
                      value={editValue.address2}
                      onChange={editChangeHandler}
                      disabled={editAddressLoading}
                    />
                    {/* {editValidator.message("address2", editValue.address2, "")} */}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Landmark *</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={editValue.landmark}
                      onChange={editChangeHandler}
                      disabled={editAddressLoading}
                    />
                    {editValidator.message(
                      "landmark",
                      editValue.landmark,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Type *</label>
                    <ReactSelect
                      options={[
                        {
                          value: "work",
                          label: "work",
                        },
                        {
                          value: "home",
                          label: "home",
                        },
                      ]}
                      onChange={(selected) => {
                        setValue((prev) => ({
                          ...prev,
                          type: selected?.value,
                        }));
                      }}
                      value={{
                        value: editValue.type,
                        label: editValue.type,
                      }}
                    />
                    {editValidator.message("type", editValue.type, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Country *</label>
                    <ReactSelect
                      loading={editAddressLoading}
                      options={countriesList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const country = selected; //here you will get full country object.
                        if (isEmpty(country)) {
                          setEditValue((prev) => ({
                            ...prev,
                            country: "",
                            state: "",
                            city: "",
                          }));
                          return;
                        }
                        setCountryid(country?.id);
                        GetState(country?.id).then((result) => {
                          setStateList(result);
                        });
                        setEditValue((prev) => ({
                          ...prev,
                          country: country?.name,
                          state: "",
                          city: "",
                        }));
                      }}
                      value={{
                        value: editValue.country,
                        label: editValue.country,
                      }}
                    />
                    {editValidator.message(
                      "country",
                      editValue.country,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>State *</label>
                    <ReactSelect
                      loading={editAddressLoading}
                      options={stateList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const state = selected; //here you will get full country object.
                        // if (isEmpty(state)) {
                        //   setEditValue((prev) => ({
                        //     ...prev,
                        //     state: "",
                        //     city: "",
                        //   }));
                        //   return;
                        // }
                        setStateid(state.id);
                        GetCity(countryid, state.id).then((result) => {
                          setCityList(result);
                        });
                        setEditValue((prev) => ({
                          ...prev,
                          state: state.name,
                          // city: "",
                        }));
                      }}
                      value={{
                        value: editValue.state,
                        label: editValue.state,
                      }}
                    />
                    {editValidator.message(
                      "state",
                      editValue.state,
                      "required"
                    )}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>City *</label>
                    <ReactSelect
                      loading={editAddressLoading}
                      options={cityList?.map((item, index) => ({
                        value: item?.name,
                        label: item?.name,
                        ...item,
                      }))}
                      onChange={(selected) => {
                        const city = selected; //here you will get full country object.
                        // if (isEmpty(city)) {
                        //   setEditValue((prev) => ({
                        //     ...prev,
                        //     city: "",
                        //   }));
                        //   return;
                        // }
                        setCityid(city.id);
                        setEditValue((prev) => ({
                          ...prev,
                          city: city.name,
                        }));
                      }}
                      value={{
                        value: editValue.city,
                        label: editValue.city,
                      }}
                    />
                    {editValidator.message("city", editValue.city, "required")}
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <label>Zip / Postal code *</label>
                    <input
                      type="text"
                      placeholder="Zip/ Postal code *"
                      name="postalCode"
                      value={editValue.postalCode}
                      onChange={editChangeHandler}
                      disabled={editAddressLoading}
                    />
                    {editValidator.message(
                      "postalCode",
                      editValue.postalCode,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onEditAddress}
              className="btn btn-primary p-10"
              disabled={editAddressLoading}
            >
              {editAddressLoading ? "Loading..." : "Edit Address"}
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* /Delete ADDRESS/ */}
      <Dialog
        // fullWidth={true}
        open={deleteAddress}
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <h4 className="mr-40"> Are You Sure You Want to Delete Address?</h4>
          <button
            className="btn-close"
            onClick={() => {
              setDeleteAddress(false);
            }}
            disabled={deleteAddressLoading}
          >
            <i className="icon-icon_close"></i>
          </button>
        </DialogTitle>
        <DialogContent>
          <div className="h-100">
            {deleteAddressLoading && <PulseLoader color="#064ACB" size={10} />}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-center items-center w-full">
            <button
              type="button"
              onClick={() => {
                setDeleteAddress(false);
              }}
              disabled={deleteAddressLoading}
              className="btn btn-secondary p-10 w-full mr-10"
            >
              No
            </button>
            <button
              type="button"
              onClick={onDeleteAddress}
              disabled={deleteAddressLoading}
              className="btn btn-primary p-10 w-full"
            >
              Confirm
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartList: state.cartList.cart,
    configs: state.configs,
    token: state.configs.token,
    symbol: state.data.symbol,
  };
};

export default connect(mapStateToProps, {
  updateConfigs,
})(AddressesSection);
