import { Rating } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const HorizontalProductCard = ({
  product,
  key,
  onTitleClick = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event?.preventDefault();
    navigate(`/product-single/${product?.slug}`);
  };
  return (
    <div className="horizontal-product-item mb-0" onClick={handleClick}>
      <div className="product-image">
        <img
          src={product.proimg?.[0]?.src}
          alt={"image - " + product.proimg?.[0]?._id}
        />
      </div>
      <div className="product-info">
        <Link onClick={onTitleClick} to={`/product-single/${product?.slug}`}>
          {product.title}{" "}
        </Link>
        <div className="flex items-center gap-10 p-0 m-0">
          <Rating
            size="small"
            name="read-only"
            precision={0.5}
            value={+product?.five_start_rating}
            readOnly
          />{" "}
          <span style={{ fontSize: "10px" }}>
            {product?.five_start_rating || 0} star rating
          </span>
        </div>
        <div className="price pt-1">
          <span className="present-price">${product.price}</span>
          <del className="old-price">${product.delPrice}</del>
        </div>
      </div>
    </div>
  );
};

export default HorizontalProductCard;
