import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import AboutPage from "../AboutPage/AboutPage";
import ShopPage from "../ShopPage";
import ProductSinglePage from "../ProductSinglePage";
import CartPage from "../CartPage";
import WishListPage from "../WishListPage";
import CheckoutPage from "../CheckoutPage";
import OrderConfirm from "../OrderConfirm";
import ComparePage from "../ComparePage";
import ErrorPage from "../ErrorPage/ErrorPage";
import LoginPage from "../LoginPage";
import ForgotPassword from "../ForgotPassword";
import RegisterPage from "../Register";
import YourOrdersPage from "../YourOrdersPage";
import ProtectedRoute from "./ProtectedRoute";
import OrderFailed from "../OrderFailed";
import UserProfilePage from "../UserProfile";

const AllRoute = () => {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="shop" element={<ShopPage />} />
        <Route path="product-single/:slug" element={<ProductSinglePage />} />
        <Route path="cart" element={<CartPage />} />
        <Route
          path="your-orders"
          element={
            <ProtectedRoute>
              <YourOrdersPage />
            </ProtectedRoute>
          }
        />
        <Route path="wishlist" element={<WishListPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="forgot" element={<ForgotPassword />} />{" "}
        <Route
          path="checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="user-profile"
          element={
            <ProtectedRoute>
              <UserProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="success/:orderId"
          element={
            <ProtectedRoute>
              <OrderConfirm />
            </ProtectedRoute>
          }
        />
        <Route
          path="failed/:orderId"
          element={
            <ProtectedRoute>
              <OrderFailed />
            </ProtectedRoute>
          }
        />
        {/* <Route path="compare" element={<ComparePage />} />
        <Route path="contact" element={<ContactPage />} />*/}
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
};

export default AllRoute;
