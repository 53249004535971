import React from "react";

const LogoutIcon = ({ color = "currentColor", size = 24 }) => {
  return (
    <svg
      stroke={color}
      fill="none"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2"></path>
      <path d="M15 12h-12l3 -3"></path>
      <path d="M6 15l-3 -3"></path>
    </svg>
  );
};

export default LogoutIcon;
