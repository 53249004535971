import React, { forwardRef } from "react";
import Select from "react-select";
import PulseLoader from "react-spinners/PulseLoader";
import { reastSelectStyles, rsTheme } from "./styles";

const ReactSelect = forwardRef(
  (
    {
      defaultValue,
      isMulti = false,
      options,
      value,
      onChange,
      isClearable = true,
      isSearchable = true,
      loading,
      isDisabled,
      ...props
    },
    ref // The forwarded ref
  ) => {
    console.log("🚀 ~ props:", props);
    return loading ? (
      <div
        className={`mc-label-field-input w-full h-35 cursor-disabled`}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "not-allowed",
          height: "35px",
          backgroundColor: "#f2f2f2",
          width: "100%",
          marginBottom: "10px",
          paddingLeft: "10px",
          height: "35px",
          borderRadius: "5px",
          // border: "none",
        }}
      >
        <PulseLoader color="#0857f5" loading={loading} size={8} />
      </div>
    ) : (
      <Select
        menuPlacement="auto"
        defaultValue={defaultValue}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        loading={loading}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        options={options}
        ref={ref} // Attach the ref to the Select component
        styles={reastSelectStyles}
        theme={rsTheme}
        {...props} // Spread the rest of the props
      />
    );
  }
);

export default ReactSelect;
