import React from "react";
import noDataFound from "../../images/No data.png";

const NoDataFound = ({ heading = "Oops! No Data Found!", subHeading = "" }) => {
  return (
    <div className="w-full border flex flex-col gap-4 justify-center items-center p-20 rounded lg">
      <h2>{heading}</h2>
      <p>{subHeading}</p>
      <img
        src={noDataFound}
        height={"300px"}
        width={"300px"}
        alt="No Data Found"
      />
    </div>
  );
};

export default NoDataFound;
