import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import {
  addToCart,
  addToCompareList,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../store/actions/action";
import Product from "./product";
import Footer from "../../components/footer/Footer";

const ProductSinglePage = (props) => {
  const addToCartProduct = async (product, qty = 1) => {
    await props?.addToCart(product, qty);
  };
  return (
    <Fragment>
      <Navbar />
      <div className="product-single-section sm-separator-padding">
        <div className="sm-container">
          <Product {...props} addToCartProduct={addToCartProduct} />
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.data.products,
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
    token: state.configs.token,
  };
};

export default connect(mapStateToProps, {
  addToCart,
  addToCompareList,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
})(ProductSinglePage);
