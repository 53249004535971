import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Dialog } from "@mui/material";

import "./style.scss"; // Import the SCSS file
import axios from "axios";
import { checkLoggedIn, getBaseURL, mergeClasses } from "../../utils";
import { store } from "../../store";
import { updateConfigs } from "../../store/actions/action";
import CloseEye from "../../icons/CloseEye";
import OpenEye from "../../icons/OpenEye";
import CustomPhoneInput from "../../components/Input/CustomPhoneInput";
import { toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import { connect } from "react-redux";
import { isValidNumber } from "libphonenumber-js";
import { useLoginModal } from "../../Context/LoginModalContext";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../icons/GoogleIcon";
import { isEmpty } from "lodash";

const Login = ({ configs, carts, className, ...props }) => {
  const { ip_data } = configs;
  const { loginModal, closeLoginModal } = useLoginModal();
  const push = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const valueRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState({
    email: "",
    password: "",
    countrycode:
      ip_data?.country_calling_code === "+971"
        ? "+1"
        : ip_data?.country_calling_code || "",
    dialCode: "",
    phone: "",
    remember: false,
  });

  console.log({ value });

  const handlePhoneChange = (phoneValue, data) => {
    // Extract the phone number without the dial code
    const dialCode = data.dialCode;
    const phoneWithoutDialCode = phoneValue.slice(data.dialCode.length);

    // Update the state with the new values
    setValue((prev) => ({
      ...prev,
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    }));
    valueRef.current = {
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    };
    // validator.showMessages();
  };

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    // validator.showMessages();
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
      validators: {
        phoneNumber: {
          message: "The :attribute must be a valid phone number.",
          rule: (val, params, validator) => {
            return isValidNumber(
              `${val.toString()}`,
              valueRef?.current?.COUNTRY_CODE
            );
          },
          required: true,
        },
      },
    })
  );

  const handelAddToCart = async (token) => {
    const data = carts?.map((el) => {
      return {
        productid: el?.id,
        quantity: el?.qty,
      };
    });
    await axios
      .post(
        "add-cart",
        { products: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const submitForm = async (e) => {
    e.preventDefault();
    // validator.showMessages();
    if (activeTab === "1") {
      if (validator.fieldValid("email") && validator.fieldValid("password")) {
        setLoading(true);
        await axios
          .post(`${getBaseURL()}v1/auth/login`, {
            email: value.email,
            password: value.password,
            logintype: "email",
          })
          .then(async (res) => {
            if (!isEmpty(carts)) {
              await handelAddToCart(res.data.token);
            }
            store.dispatch(
              updateConfigs({
                isAuth: true,
                token: res.data.token,
                ...res.data.userdetails,
              })
            );

            if (location?.state?.pathname) {
              push(location?.state?.pathname);
            } else if (location.pathname === "/login") {
              carts.length > 0 ? push("/cart") : push("/");
            }
            if (loginModal) closeLoginModal();
            setLoading(false);
            toast.success(res?.data?.message);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
          });
      } else {
        validator.showMessages();
        setValue((prev) => ({ ...prev }));
      }
    } else {
      if (validator.fieldValid("phone") && validator.fieldValid("password")) {
        setLoading(true);
        await axios
          .post(`${getBaseURL()}v1/auth/login`, {
            mobileno: value.phone,
            password: value.password,
            logintype: "mobile",
          })
          .then(async (res) => {
            if (!isEmpty(carts)) {
              await handelAddToCart(res.data.token);
            }
            store.dispatch(
              updateConfigs({
                isAuth: true,
                token: res.data.token,
                ...res.data.userdetails,
              })
            );

            if (location?.state?.pathname) {
              push(location?.state?.pathname);
            } else if (location.pathname === "/login") {
              carts.length > 0 ? push("/cart") : push("/");
            }

            if (loginModal) closeLoginModal();
            setLoading(false);
            toast.success(res?.data?.message);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
          });
      } else {
        validator.showMessages();
        setValue((prev) => ({ ...prev }));
      }
    }
  };

  const handleGoogleLogin = async (userInfo) => {
    setLoading(true);
    await axios
      .post(`register-oauth-google`, {
        token: { data: userInfo },
      })
      .then((res) => {
        store.dispatch(
          updateConfigs({
            isAuth: true,
            token: res.data.token,
            ...res.data.userdetails,
          })
        );
        setLoading(false);
        toast.success("Logged In Successfully.");

        if (location?.state?.pathname) push(location?.state?.pathname);
        else if (location.pathname === "/login") push("/");
        if (loginModal) closeLoginModal();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );
        if (userInfo?.status === 200) {
          handleGoogleLogin(userInfo.data);
        }
      } catch (error) {
        toast.error("Error fetching user info:", error);
      }
    },
    onError: (error) => {
      toast.error("Login failed:", error);
    },
  });

  useEffect(() => {
    if (checkLoggedIn()) {
      push(-1);
    }
  }, []);

  return (
    <>
      <div className={mergeClasses("login-form", className)}>
        <div className="login-header">
          <h2>Login</h2>
        </div>
        <Nav tabs className="login-tabs">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
              style={{ borderRadius: "30px 0px 0px 30px", padding: "4px" }}
              disabled={loading}
            >
              <p
                style={
                  activeTab === "1"
                    ? {
                        color: "#FAFAFA",
                        marginBottom: "0px",
                        border: "1px",
                        borderRadius: "30px",
                      }
                    : { marginBottom: "0px", fontWeight: "500" }
                }
              >
                Email
              </p>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
              style={{ borderRadius: "0px 30px 30px 0px", padding: "4px" }}
              disabled={loading}
            >
              <p
                style={
                  activeTab === "2"
                    ? {
                        color: "#FAFAFA",
                        marginBottom: "0px",
                      }
                    : { marginBottom: "0px", fontWeight: "500" }
                }
              >
                Phone
              </p>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <form className="contact-form" onSubmit={submitForm}>
              <div className="form-field">
                <label>Email Address *</label>
                <input
                  type="email"
                  value={value.email}
                  name="email"
                  onChange={changeHandler}
                  disabled={loading}
                />
                {validator.message("email", value.email, "required|email")}
              </div>
              <div className="form-field">
                <label>Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={value.password}
                  name="password"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                  disabled={loading}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>
                {validator.message("password", value.password, "required")}
              </div>

              <Link
                to={loading ? "#" : "/forgot"}
                className="forgot"
                disabled={loading}
              >
                Lost your password?
              </Link>
              <div className="form-field">
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="small-btn-style"
                  disabled={loading}
                >
                  {loading ? (
                    <PulseLoader size={10} color={"#fafafa"} />
                  ) : (
                    "Login Now"
                  )}
                </button>
              </div>
            </form>
          </TabPane>
          <TabPane tabId="2">
            <form className="contact-form" onSubmit={submitForm}>
              <div className="form-field">
                <label>Phone Number *</label>
                {/* <input
                  type="number"
                  value={value.phone}
                  name="phone"
                  onChange={changeHandler}
                /> */}
                <CustomPhoneInput
                  inputStyle={{
                    padding: "18.5px 14px 16px 58px",
                    height: "30px",
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    border: "2px solid transparent",
                  }}
                  value={`${value?.countrycode}${value?.phone}`}
                  name="phone"
                  onChange={handlePhoneChange}
                  disabled={loading}
                />
                {validator.message(
                  "phone",
                  value.phone,
                  "required|phoneNumber"
                )}
              </div>
              <div className="form-field">
                <label>Password *</label>

                <input
                  type={showPassword ? "text" : "password"}
                  value={value.password}
                  name="password"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                  disabled={loading}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>

                {validator.message("password", value.password, "required")}
              </div>
              <Link to={loading ? "#" : "/forgot"} className="forgot">
                Lost your password?
              </Link>
              <div className="form-field">
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="small-btn-style flex items-center justify-center"
                  disabled={loading}
                >
                  {loading ? (
                    <PulseLoader size={10} color={"#fafafa"} />
                  ) : (
                    "Login Now"
                  )}
                </button>
              </div>
            </form>
          </TabPane>
        </TabContent>
        {/* <button onClick={googleLogin} class="google-login-button">
          <GoogleIcon />
          <span>Log in with Google</span>
        </button> */}
        <Link to={loading ? "#" : "/register"} className="forgot">
          Don’t Have an Account?
        </Link>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(Login);
