import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";

import "./style.scss";
import { checkLoggedIn, getBaseURL } from "../../utils";
import axios from "axios";
import CloseEye from "../../icons/CloseEye";
import OpenEye from "../../icons/OpenEye";
import { isValidNumber } from "libphonenumber-js";
import CustomPhoneInput from "../../components/Input/CustomPhoneInput";
import { connect } from "react-redux";
import { updateConfigs } from "../../store/actions/action";
import { PulseLoader } from "react-spinners";
import { isEmpty } from "lodash";

const Register = ({ configs, updateConfigs, carts }) => {
  const { ip_data } = configs;
  const navigate = useNavigate();

  const [value, setValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    new_password: "",
    confirm_password: "",
    countrycode: ip_data?.country_calling_code === "+971" ? "+1" : ip_data?.country_calling_code || "",
    phone: "",
    gender: "",
    dob: "",
    remember: false,
  });
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState({
    sent: false,
    mobile_otp: "",
    email_otp: "",
    timer: 60,
  });

  const valueRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const otpTimerRef = useRef(null);

  useEffect(() => {
    if (otp.timer === 0) {
      clearInterval(otpTimerRef.current);
    }
    return () => {
      if (otpTimerRef.current) {
        clearInterval(otpTimerRef.current);
      }
    };
  }, [otp.timer]);

  const validateDateOfBirth = (dob) => {
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age >= 18;
  };

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    // validator.showMessages();
  };

  const handlePhoneChange = (phoneValue, data) => {
    // Extract the phone number without the dial code
    const dialCode = data.dialCode;
    const phoneWithoutDialCode = phoneValue.slice(data.dialCode.length);

    // Update the state with the new values
    setValue((prev) => ({
      ...prev,
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    }));
    valueRef.current = {
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    };
    // validator.showMessages();
  };

  const otpChangeHandler = (e) => {
    setOtp((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
      validators: {
        phoneNumber: {
          message: "The :attribute must be a valid phone number.",
          rule: (val, params, validator) => {
            return isValidNumber(
              `${val.toString()}`,
              valueRef?.current?.COUNTRY_CODE
            );
          },
          required: true,
        },
        strongPassword: {
          // Name the rule
          message:
            "The :attribute must be 8+ characters, include digits (0-9), letters (a-z, A-Z), and special characters (!@#$)",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            );
          },
          messageReplace: (message, params) => message, // You can customize this further if needed
          required: true, // Makes the field required
        },
      },
    })
  );

  const submitForm = async (e) => {
    e.preventDefault();
    // Age validation
    // if (!validateDateOfBirth(value?.dob)) {
    //   validator.showMessageFor("dob");
    //   toast.error("You must be at least 18 years old & Above.");
    //   setValue({ ...value });
    //   return;
    // }

    if (validator.allValid()) {
      let registerData = {
        fullname: `${value.first_name} ${value.last_name}`,
        email: value.email,
        countrycode: value?.countrycode.includes("+")
          ? value?.countrycode
          : `+${value?.countrycode}`,
        mobileno: value.phone,
        password: value.new_password,
        dateOfBirth: value.dob, // send in date format
        gender: value.gender,
      };
      setLoading(true);
      await axios
        .post(`${getBaseURL()}v1/auth/register`, { ...registerData })
        .then(async (res) => {
          setOtp((prev) => ({ ...prev, sent: true }));
          otpTimerRef.current = setInterval(() => {
            setOtp((prev) => {
              return { ...prev, timer: prev.timer - 1 };
            });
          }, 1000);
          toast.success("OTP Sent Successfully");
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setOtp((prev) => ({ ...prev, sent: false }));
          setLoading(false);
        });
    } else {
      validator.showMessages();
      setValue({ ...value });
      let errors = Object.keys(validator.errorMessages);
      for (let i = 0; i < errors.length; i++) {
        if (validator.errorMessages[errors[i]]) {
          toast.error(validator.errorMessages[errors[i]]);
          return;
        }
      }
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    let otpData = {
      email: value.email,
      countrycode: value?.countrycode.includes("+")
        ? value?.countrycode
        : `+${value?.countrycode}`,
      mobileno: value.phone,
      email_otp: otp.email_otp,
      mobile_otp: otp.mobile_otp,
    };
    if (otp.email_otp && otp.mobile_otp) {
      setLoading(true);
      await axios
        .post(`${getBaseURL()}v1/auth/verifyregisterotp`, {
          ...otpData,
        })
        .then(async (res) => {
          if (!isEmpty(carts) && res?.data?.token) {
            await handelAddToCart(res?.data?.token);
          }
          toast.success(res.data.message);
          updateConfigs({
            isAuth: true,
            token: res.data.token,
            ...res.data.userdetails,
          });
          navigate("/");
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
          setLoading(false);
        });
    } else {
      setOtp({ ...otp });
      toast.error("Input cannot be Empty");
      validator.showMessages();
    }
  };

  const resendOtp = async () => {
    await axios
      .post(`${getBaseURL()}v1/auth/resendregisterotp`, {
        email: value.email,
        mobileno: value.phone,
        countrycode: value?.countrycode.includes("+")
          ? value?.countrycode
          : `+${value?.countrycode}`,
      })
      .then((res) => {
        toast.success("OTP resent successfully!");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  const handelAddToCart = async (token) => {
    const data = carts?.map((el) => {
      return {
        productid: el?.id,
        quantity: el?.qty,
      };
    });
    await axios
      .post(
        "add-cart",
        { products: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    if (checkLoggedIn()) {
      navigate(-1);
    }
  }, []);

  return (
    <Dialog open={true} size={"full"}>
      <button
        onClick={() => {
          navigate("/");
        }}
        className="btn btn-close"
        disabled={loading}
      >
        <i className="icon-icon_close"></i>
      </button>
      {otp.sent ? (
        <DialogContent>
          <div
            className="login-form mt-0"
            style={{ padding: "0px", margin: "0px" }}
          >
            <div className="login-header">
              <h2>Verify OTP</h2>
            </div>
            <form
              className="contact-form"
              style={{ marginTop: "0px" }}
              onSubmit={verifyOtp}
            >
              <div className="form-field">
                <label>Mobile OTP *</label>
                <input
                  type="text"
                  value={otp.mobile_otp}
                  name="mobile_otp"
                  onChange={otpChangeHandler}
                  disabled={loading}
                />
                {validator.message("mobile_otp", otp.mobile_otp, "required")}
              </div>
              <div className="form-field">
                <label>Email OTP *</label>
                <input
                  type="text"
                  value={otp.email_otp}
                  name="email_otp"
                  onChange={otpChangeHandler}
                  disabled={loading}
                />
                {validator.message("email_otp", otp.email_otp, "required")}
              </div>
              <div className="otp-timer-container">
                <p>0.{otp.timer}</p>{" "}
                <button disabled={otp.timer > 0}>Resend Otp</button>
              </div>
              <button
                type="submit"
                className="small-btn-style"
                style={{
                  width: "100%",
                  marginTop: "20px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <PulseLoader size={10} color={"#fafafa"} />
                ) : (
                  "Verify"
                )}
              </button>
            </form>
          </div>
        </DialogContent>
      ) : (
        <div className="login-form mt-0">
          <div className="">
            <div className="login-header">
              <h2>register</h2>
            </div>
            <form className="contact-form" onSubmit={submitForm}>
              <div className="form-field">
                <div className="responsive-row">
                  <div>
                    <label>First Name *</label>
                    <input
                      type="text"
                      value={value.first_name}
                      name="first_name"
                      onChange={changeHandler}
                      disabled={loading}
                    />
                    {validator.message(
                      "first_name",
                      value.first_name,
                      "required"
                    )}
                  </div>
                  <div>
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={value.last_name}
                      name="last_name"
                      onChange={changeHandler}
                      disabled={loading}
                    />
                    {validator.message("last_name", value.last_name, "")}
                  </div>
                </div>
              </div>
              <div className="form-field">
                <label>Email Address *</label>
                <input
                  type="email"
                  value={value.email}
                  name="email"
                  onChange={changeHandler}
                  disabled={loading}
                />
                {validator.message("email", value.email, "required|email")}
              </div>
              <div className="form-field">
                <label>Phone Number *</label>
                <CustomPhoneInput
                  inputStyle={{
                    padding: "18.5px 14px 16px 58px",
                    height: "30px",
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    border: "2px solid transparent",
                  }}
                  value={`${value?.countrycode}${value?.phone}`}
                  name="phone"
                  onChange={handlePhoneChange}
                  disabled={loading}
                />
                {validator.message(
                  "phone",
                  value.phone,
                  "required|phoneNumber"
                )}
              </div>
              <div className="form-field">
                <label>New Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={value.new_password}
                  name="new_password"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>
                {validator.message(
                  "new_password",
                  value.new_password,
                  "required|strongPassword"
                )}
              </div>
              <div className="form-field">
                <label>Confirm New Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={value.confirm_password}
                  name="confirm_password"
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>
                {validator.message(
                  "confirm_password",
                  value.confirm_password,
                  `required|in:${value.new_password}`,
                  {
                    messages: {
                      in: "Passwords does'nt match.",
                    },
                    element: (message) =>
                      value.new_password &&
                      value.new_password === value.confirm_password ? (
                        <span style={{ color: "green", width: "100%" }}>
                          Passwords match.
                        </span>
                      ) : (
                        <span className="errorMessage">{message}</span>
                      ),
                  }
                )}
              </div>
              <div className="responsive-row">
                <div style={{ width: "100%" }} className="form-field">
                  <label>Select Gender *</label>
                  <div>
                    <div className="input-box">
                      <input
                        type="radio"
                        id="male"
                        checked={value.gender === "male"}
                        onChange={() =>
                          setValue((prev) => ({ ...prev, gender: "male" }))
                        }
                        name="gender"
                        disabled={loading}
                      />
                      <label htmlFor="male">Male</label>
                    </div>
                    <div className="input-box">
                      <input
                        type="radio"
                        id="female"
                        checked={value.gender === "female"}
                        onChange={() =>
                          setValue((prev) => ({ ...prev, gender: "female" }))
                        }
                        name="gender"
                        disabled={loading}
                      />
                      <label htmlFor="female">Female</label>
                    </div>
                  </div>
                  {validator.message("gender", value.gender, "required")}
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                  className="form-field"
                >
                  <label>Date of Birth *</label>
                  <div>
                    <DatePicker
                      placeholderText={"MM-DD-YYYY"}
                      showYearDropdown
                      selected={value.dob}
                      onChange={(date) =>
                        setValue((prev) => ({ ...prev, dob: date }))
                      }
                      disabled={loading}
                    />
                    {validator.message("dob", value.dob, "required")}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div>
                  <button
                    type="submit"
                    className="small-btn-style"
                    style={{
                      zIndex: 0,
                      width: "100%",
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <PulseLoader size={10} color={"#fafafa"} />
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
                <Link to={loading ? "#" : "/login"} className="forgot">
                  Already Have an Account?
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    configs: state.configs,
  };
};
export default connect(mapStateToProps, { updateConfigs })(Register);
