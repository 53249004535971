import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { mergeClasses } from "../../../utils";
import smallLogo from "../../../images/small-logo.png";

const SmallLogo = ({
  className = "",
  to = "#",
  alt = "logo",
  sx,
  imgStyle = { objectFit: "contain", height: "100%" },
}) => {
  return (
    <Link
      to={to}
      className={mergeClasses(
        "h-45 flex justify-start items-center",
        `${className}`
      )}
    >
      <img src={smallLogo || ""} alt={alt} style={imgStyle} />
    </Link>
  );
};

export default SmallLogo;
