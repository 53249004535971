import React, { useState } from "react";
import { checkLoggedIn } from "../../utils";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../icons/DeleteIcon";
import { MoonLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";
import { fCurrency } from "../../utils/formatNumber";

const CartProductRow = ({ cartItem, key, ...props }) => {
  const [loadingState, setLoadingState] = useState({
    buyNow: false,
    addToCart: false,
    increment: false,
    decrement: false,
    delete: false,
  }); // Separate loading states
  const navigate = useNavigate();

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      await props?.incrementQuantity(cartItem.id); // Wait for the API call to complete
      setLoadingState({ ...loadingState, increment: false }); // Stop loading
    } else {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      setTimeout(() => {
        props?.incrementQuantity(cartItem.id);
        setLoadingState({ ...loadingState, increment: false }); // Stop loading
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      if (cartItem.qty === 1) {
        await props?.removeFromCart(cartItem.id); // Wait for the remove action
      } else {
        await props?.decrementQuantity(cartItem.id); // Wait for decrement action
      }
      setLoadingState({ ...loadingState, decrement: false }); // Stop loading // Stop loading
    } else {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      if (cartItem.qty === 1) {
        setTimeout(() => {
          props?.removeFromCart(cartItem.id);
          setLoadingState({ ...loadingState, decrement: false }); // Stop loading
        }, 1000);
      } else {
        setTimeout(() => {
          props?.decrementQuantity(cartItem.id);
          setLoadingState({ ...loadingState, decrement: false }); // Stop loading
        }, 1000);
      }
    }
  };

  return (
    <tr key={key}>
      <td className="product">
        <ul>
          <li>
            <img
              src={cartItem?.proimg?.[0]?.src}
              alt={cartItem?.proimg?.[0]?.id}
            />
          </li>
          <li className="cart-item-title">{cartItem.title}</li>
        </ul>
      </td>
      <td className="price">${cartItem.price}</td>
      <td className="quantity">
        <ul className="input-style flex gap-10">
          <button
            className="decQtybutton"
            onClick={(e) => {
              e.preventDefault();
              handleDecrementQuantity();
            }}
            disabled={
              loadingState?.buyNow ||
              loadingState?.addToCart ||
              loadingState?.increment ||
              loadingState?.decrement ||
              loadingState?.delete
            } // Disable all other buttons while loading
          >
            {loadingState?.decrement ? (
              <MoonLoader size={16} color="#fafafa" /> // Show spinner
            ) : cartItem.qty === 1 ? (
              <DeleteIcon />
            ) : (
              "-"
            )}
          </button>
          {cartItem.qty}
          <button
            className="incQtybutton"
            onClick={(e) => {
              e.preventDefault();
              handleIncrementQuantity();
            }}
            disabled={
              loadingState?.buyNow ||
              loadingState?.addToCart ||
              loadingState?.increment ||
              loadingState?.decrement ||
              loadingState?.delete
            } // Disable all other buttons while loading
          >
            {loadingState?.increment ? (
              <MoonLoader size={16} color="#fafafa" />
            ) : (
              "+"
            )}
          </button>
        </ul>
      </td>
      <td className="subtotal">${fCurrency(cartItem.qty * cartItem.price)}</td>
      <td className="actions">
        <button
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            setLoadingState({ ...loadingState, delete: true }); // Start loading
            await props?.removeFromCart(cartItem.id);
            setTimeout(() => {
              setLoadingState({ ...loadingState, delete: false }); // Stop loading
            }, 1000);
          }}
          data-tooltip-id="wish-tooltip"
          data-tooltip-content="Remove from Cart"
          data-tooltip-place="top"
          className="incQtybutton"
          disabled={
            loadingState?.buyNow ||
            loadingState?.addToCart ||
            loadingState?.increment ||
            loadingState?.decrement ||
            loadingState?.delete
          } // Disable all other buttons while loading
        >
          {loadingState?.delete ? (
            <MoonLoader size={16} color="#fafafa" />
          ) : (
            <DeleteIcon />
          )}
        </button>
        <Tooltip id="cart-tooltip" />
      </td>
    </tr>
  );
};

export default CartProductRow;
