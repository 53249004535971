import React, { useEffect, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import ViewModal from "../Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import { toast } from "react-toastify";
import ProductCard from "../Cards/ProductCard";
import { Pagination } from "@mui/material";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import LoadingCard from "../Cards/LoadingCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const PopularCategories = ({
  addToCartProduct,
  addToWishListProduct,
  addToCompareListProduct,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const [popularcategories, setPopularcategories] = useState([]);
  const [state, setState] = useState({});
  const [value, setValue] = React.useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 4,
    totalRecords: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  const handleClickOpen = (item) => {
    setOpen(true);
    setState(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const fetchPopularcategories = () => {
    setLoading(true);
    if (error) setError(false);
    axios
      .get("get-products", {
        params: { popularcategories: true, ...filter },
      })
      .then((response) => {
        const data = response?.data?.data;
        setPopularcategories(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setPopularcategories([]);
        setError(true);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPopularcategories();
  }, [filter.page]);

  return (
    <section className="popular-category-section sm-separator-padding">
      <div className="sm-container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <SectionTitle
              sTitle={"Popular Categories"}
              sClass={"heading-title-s2"}
            />
          </div>
        </div>
        {loading ? (
          <>
            <div className="grid-4 w-full">
              {new Array(filter?.limit || 4).fill(1)?.map((el) => {
                return <LoadingCard />;
              })}
            </div>
            <div className="flex-justify-center-items-center pt-40 pb-40"></div>
          </>
        ) : popularcategories.length === 0 ? (
          <div>
            <h3>Oops! No Data Found!</h3>
          </div>
        ) : (
          <div className="product-wrap">
            <div className="grid-4">
              {popularcategories.length > 0 && (
                <>
                  {popularcategories.map((product, index) => (
                    <ProductCard
                      product={product}
                      key={index}
                      addToWishListProduct={addToWishListProduct}
                      addToCartProduct={addToCartProduct}
                      handleClickOpen={handleClickOpen}
                      carts={props.carts}
                      className="col-xl-12 col-lg-12 col-sm-12 col-12"
                      {...props}
                    />
                  ))}
                </>
              )}
            </div>
            {popularcategories.length > 0 && (
              <div className="flex-justify-center-items-center pt-20 pb-20">
                <Pagination
                  count={Math.ceil(filter.totalRecords / filter.limit)}
                  size={"large"}
                  page={filter?.page}
                  defaultPage={filter?.page}
                  onChange={handlePageChange}
                  siblingCount={0}
                />
              </div>
            )}
          </div>
        )}
        {/* <div className="product-wrap">
          <div className="product-tab">
            <ul className="nav nav-mb-3 main-tab" id="tab" role="tablist">
              <Tabs
                value={value}
                classes={{
                  root: "prdTab",
                  indicator: "prdTabIndicator",
                }}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Medical Supplies" />
                <Tab label="Beauty" />
                <Tab label="Face Masks" />
              </Tabs>
            </ul>
          </div>
          <div className="tab-content">
            <TabPanel value={value} index={0}>
              <div className="row">
                {products.length > 0 &&
                  products.slice(0, 4).map((product, pitem) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-12"
                      key={pitem}
                    >
                      <div className="product-item">
                        <div className="product-image">
                          <img src={product.proImg} alt="" />
                          <ul className="tag-wrap">
                            {product.badge1 ? (
                              <li className={`tag ${product.badgeClass}`}>
                                {product.badge1}
                              </li>
                            ) : (
                              ""
                            )}
                            {product.badge2 ? (
                              <li className={`tag ${product.badgeClass2}`}>
                                {product.badge2}
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                          <ul className="info-wrap">
                            <li>
                              <button
                                data-tooltip-id="wish-tooltip"
                                data-tooltip-content="Add to Wishlist"
                                data-tooltip-place="left"
                                onClick={() => addToWishListProduct(product)}
                              >
                                <i className="icon-heart"></i>
                              </button>
                              <Tooltip id="wish-tooltip" />
                            </li>

                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#modal-cart-view"
                            >
                              <button
                                data-tooltip-id="view-tooltip"
                                data-tooltip-content="Quick View"
                                data-tooltip-place="left"
                                onClick={() => handleClickOpen(product)}
                              >
                                <i className="icon-open-eye-1"></i>
                              </button>
                              <Tooltip id="view-tooltip" />
                            </li>
                          </ul>
                        </div>
                        <div className="product-info">
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/product-single/${product.slug}`}
                            >
                              {product.title}
                            </Link>
                          </h2>
                          <div className="rating-product">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </div>
                          <div className="price">
                            <span className="present-price">
                              ${product.price}
                            </span>
                            <del className="old-price">${product.delPrice}</del>
                            <button
                              className="cart-btn"
                              data-tooltip-id="cart-tooltip"
                              data-tooltip-content="add to cart"
                              data-tooltip-place="left"
                              onClick={() => addToCartProduct(product)}
                            >
                              <i className="icon-cart"></i>
                            </button>
                            <Tooltip id="cart-tooltip" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="row">
                {products.length > 0 &&
                  products.slice(4, 8).map((product, pitem) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-12"
                      key={pitem}
                    >
                      <div className="product-item">
                        <div className="product-image">
                          <img src={product.proImg} alt="" />
                          <ul className="tag-wrap">
                            {product.badge1 ? (
                              <li className={`tag ${product.badgeClass}`}>
                                {product.badge1}
                              </li>
                            ) : (
                              ""
                            )}
                            {product.badge2 ? (
                              <li className={`tag ${product.badgeClass2}`}>
                                {product.badge2}
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                          <ul className="info-wrap">
                            <li>
                              <button
                                data-tooltip-id="wish-tooltip"
                                data-tooltip-content="Add to Wishlist"
                                data-tooltip-place="left"
                                onClick={() => addToWishListProduct(product)}
                              >
                                <i className="icon-heart"></i>
                              </button>
                              <Tooltip id="wish-tooltip" />
                            </li>
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#modal-cart-view"
                            >
                              <button
                                data-tooltip-id="view-tooltip"
                                data-tooltip-content="Quick View"
                                data-tooltip-place="left"
                                onClick={() => handleClickOpen(product)}
                              >
                                <i className="icon-open-eye-1"></i>
                              </button>
                              <Tooltip id="view-tooltip" />
                            </li>
                          </ul>
                        </div>
                        <div className="product-info">
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/product-single/${product.slug}`}
                            >
                              {product.title}
                            </Link>
                          </h2>
                          <div className="rating-product">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </div>
                          <div className="price">
                            <span className="present-price">
                              ${product.price}
                            </span>
                            <del className="old-price">${product.delPrice}</del>
                            <button
                              className="cart-btn"
                              data-tooltip-id="cart-tooltip"
                              data-tooltip-content="add to cart"
                              data-tooltip-place="left"
                              onClick={() => addToCartProduct(product)}
                            >
                              <i className="icon-cart"></i>
                            </button>
                            <Tooltip id="cart-tooltip" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="row">
                {products.length > 0 &&
                  products.slice(8, 12).map((product, pitem) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-12"
                      key={pitem}
                    >
                      <div className="product-item">
                        <div className="product-image">
                          <img src={product.proImg} alt="" />
                          <ul className="tag-wrap">
                            {product.badge1 ? (
                              <li className={`tag ${product.badgeClass}`}>
                                {product.badge1}
                              </li>
                            ) : (
                              ""
                            )}
                            {product.badge2 ? (
                              <li className={`tag ${product.badgeClass2}`}>
                                {product.badge2}
                              </li>
                            ) : (
                              ""
                            )}
                            {product.badge3 ? (
                              <li className={`tag ${product.badgeClass3}`}>
                                {product.badge3}
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                          <ul className="info-wrap">
                            <li>
                              <button
                                data-tooltip-id="wish-tooltip"
                                data-tooltip-content="Add to Wishlist"
                                data-tooltip-place="left"
                                onClick={() => addToWishListProduct(product)}
                              >
                                <i className="icon-heart"></i>
                              </button>
                              <Tooltip id="wish-tooltip" />
                            </li>

                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#modal-cart-view"
                            >
                              <button
                                data-tooltip-id="view-tooltip"
                                data-tooltip-content="Quick View"
                                data-tooltip-place="left"
                                onClick={() => handleClickOpen(product)}
                              >
                                <i className="icon-open-eye-1"></i>
                              </button>
                              <Tooltip id="view-tooltip" />
                            </li>
                          </ul>
                        </div>
                        <div className="product-info">
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/product-single/${product.slug}`}
                            >
                              {product.title}
                            </Link>
                          </h2>
                          <div className="rating-product">
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                            <i className="icon-star"></i>
                          </div>
                          <div className="price">
                            <span className="present-price">
                              ${product.price}
                            </span>
                            <del className="old-price">${product.delPrice}</del>
                            <button
                              className="cart-btn"
                              data-tooltip-id="cart-tooltip"
                              data-tooltip-content="add to cart"
                              data-tooltip-place="left"
                              onClick={() => addToCartProduct(product)}
                            >
                              <i className="icon-cart"></i>
                            </button>
                            <Tooltip id="cart-tooltip" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </TabPanel>
          </div>
        </div> */}
      </div>
      <ViewModal
        addToCartProduct={addToCartProduct}
        open={open}
        onClose={handleClose}
        product={state}
        carts={props.carts}
        {...props}
      />
    </section>
  );
};

export default PopularCategories;
